.authorization {
    background: #2e2e48;
    background-image: linear-gradient(to left bottom, #2e2e48, #2a2a45, #272742, #232340, #20203d);
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 20px;
}


.game-logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.game-logo span {
    font-weight: 1000;
    font-size: 26px;
    line-height: 26px;
    background: #03a9f4;
    border-radius: 15px;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-right: 10px;
    margin-top: 10px;
}

.game-logo span img {
    width: 30px;
    height: 30px;
}

.game-logo h1 {
    font-weight: 1000;
    font-size: 36px;
    color: white;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.game-logo-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.game-logo-text {
    color: white;
    font-family: "Red Hat Display", sans-serif;
    font-weight: 300;
}

@media screen and (max-width: 350px) {
    .game-logo h1 {
        font-size: 30px;
    }
}


.authorization-wrapper {
    width: 100%;
    max-width: 400px;
    background: white;
    padding: 20px;
    border-radius: 20px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
}

.authorization h2 {
    font-weight: 600;
    text-align: center;
    font-size: 20px;
    margin-bottom: 20px;
}

.authorization .button {
    background: #03a9f4;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    border-radius: 25px;
    color: white;
    font-weight: 800;
    cursor: pointer;
    transition: transform 0.3s;
}

.authorization .button:hover {
    transform: scale(1.05);
}

.authorization p {
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
    color: #777;
    display: flex;
    justify-content: center;
    align-items: center;
}

.authorization p a {
    display: block;
    color: #03a9f4;
    text-decoration: none;
    position: relative;
    overflow: hidden;
    margin-left: 5px;
}

.authorization p a::after {
    content: "";
    width: 100%;
    height: 1px;
    background: #03a9f4;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    transition: transform 0.2s;
    transform: translateX(-101%);
}

.authorization p a:hover::after {
    transform: translateX(0);
}


.authorization .error {
    display: flex;
    align-self: center;
    background: #f44336;
    font-size: 12px;
    font-weight: bold;
    padding: 3px 5px;
    border-radius: 5px;
    color: white;
    text-align: center;
    margin-top: 10px;
}