/* PAGE HEADER STYLES */
.dashboard-header {
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
}

.dashboard-header-user {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-header-text {
    font-size: 24px;
    color: white;
    font-family: "Red Hat Display", 'Google Sans', sans-serif;
    opacity: 0.3;
}

.dashboard-header-title {
    font-size: 24px;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    font-family: "Red Hat Display", 'Google Sans', sans-serif;
    padding-left: 10px;
}

.dashboard-header-filter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-filter {
    margin-left: 20px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.2);
    padding: 0 20px;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.dashboard-filter:hover {
    background: rgba(255, 255, 255, 0.4);
}

.dashboard-filter img {
    width: 14px;
    height: 14px;
    object-fit: contain;
    filter: invert(1)
}

.dashboard-filter-text {
    color: white;
    font-size: 14px;
    font-weight: bold;
    padding-left: 7px;
}

.dashboard-filter-text-value {
    font-size: 11px;
    font-weight: normal;
}

.dashboard-xls-button {
    margin-left: 15px;
    width: 70px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ff9800;
    border-radius: 20px;
    font-size: 13px;
    font-family: "Red Hat Display", sans-serif;
    color: white;
    font-weight: 1000;
    cursor: pointer;
    transition: box-shadow 0.4s;
}

.dashboard-xls-button:hover {
    box-shadow: 0 0 10px #ff9800;
}



@media screen and (max-width: 600px) {

    .dashboard-header {
        flex-direction: column;
        height: 80px;
        align-items: flex-start;
    }

    .dashboard-filter {
        margin-left: -5px;
        margin-top: 10px;
        padding: 0 20px;
    }

    .dashboard-header-text {
        font-size: 13px;
    }

    .dashboard-header-title {
        font-size: 15px;
        padding-left: 5px;
    }

    .dashboard-xls-button {
        margin-top: 10px;
    }

}

/* CONTAINER */
.dashboard-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}



/* TOP WIDGET */
.dashboard-top-widget {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.dashboard-top-numbers-widget {
    width: calc(50% - 60px);
    margin-left: 60px;
}

.dashboard-top-angle-widget {
    width: 100%;
    background-image: linear-gradient(to bottom, #8634b5, #8E49B4, #A053CA, #8E49B4, #8634b5);
    display: flex;
    height: 80px;
    position: relative;
    border-radius: 0 20px 20px 0;
    border-bottom: 1px solid #A053CA;
}

.dashboard-top-angle-widget::after {
    content: "";
    position: absolute;
    left: -40px;
    width: 80px;
    height: 80px;
    clip-path: path('M3.06996 29.554C-4.17651 16.2271 5.47087 0 20.6404 0H67V80H42.3904C35.0621 80 28.3207 75.992 24.82 69.554L3.06996 29.554Z');
    z-index: 0;
    background-image: linear-gradient(to bottom, #8634b5, #8E49B4, #A053CA, #8E49B4, #8634b5);
}

.dashboard-top-angle-widget-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20%;
    padding-right: 20px;
    position: relative;
    z-index: 1;
}

.dashboard-top-angle-widget-box:last-child {
    background: #2196f3;
    border-radius: 0 20px 20px 0;
    height: 80px;
    padding-right: 0;
    box-shadow: 0 0 10px #2196f3;
    position: relative;
}

.dashboard-top-angle-widget-text {
    font-size: 13px;
    color: white;
    font-weight: 700;
    text-align: center;
}

.dashboard-top-angle-widget-value {
    font-size: 20px;
    font-weight: 700;
    color: white;
    margin-top: 4px;
    font-family: "Red Hat Display", 'Google Sans', sans-serif;
    text-align: center;
}

.dashboard-top-instuments-widget {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-left: -20px;
    opacity: 0.5;
}

.dashboard-top-instument-note {
    background: #ff9800;
    height: 80px;
    width: calc(100% - 20px);
    margin: 30px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    /* box-shadow: 0 0 5px #ff9800; */
    cursor: pointer;
    transition: transform 0.3s;
    cursor: default;
}

/*.dashboard-top-instument-note:hover {
    transform: scale(1.03);
}*/

.dashboard-top-instument-activity {
    background: #8bc34a;
    height: 80px;
    width: calc(100% - 20px);
    margin: 40px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    /*box-shadow: 0 0 5px #8bc34a; */
    cursor: pointer;
    transition: transform 0.3s;
    cursor: default;
}

.dashboard-top-instument-text {
    font-size: 20px;
    color: white;
    font-weight: bold;
    padding-left: 5px;
}

.dashboard-top-instument-note img,
.dashboard-top-instument-activity img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    filter: invert(1);
}


/* Battery Widget */
.dashboard-battery-widget {
    width: 50%;
    background-image: linear-gradient(to bottom, #3f51b5, #3a4ba8, #35459b, #313f8e, #2c3981);
    padding: 20px;
    margin-bottom: 30px;
    border-radius: 20px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    padding-right: 20px;
}

.dashboard-battery-widget-container {
    border: 2px solid rgba(255, 255, 255, 0.8);
    border-radius: 20px;
    height: 120px;
    position: relative;
    background: rgba(0, 0, 0, 0.5);
}

.dashboard-battery-widget-container::after {
    content: "";
    background: rgba(255, 255, 255, 0.8);
    z-index: 1;
    width: 10px;
    height: 30px;
    position: absolute;
    right: -16px;
    top: 40px;
    border-radius: 3px;
}

.dashboard-battery-widget-layout {
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 18px;
}

.dashboard-battery-widget-layout::after {
    content: "PROFIT";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Red Hat Display", 'Google Sans', sans-serif;
    font-weight: 1000;
    font-size: 70px;
    color: rgba(255, 255, 255, 0.05);
    position: absolute;
    z-index: 0;
}

.dashboard-battery-widget-container-inner {
    width: calc(5% - 25px);
    height: 116px;
    background: #8bc34a;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100px;
    max-width: calc(100% - 20px);
    z-index: 1;
}

.dashboard-battery-widget-container-text {
    font-size: 30px;
    font-family: "Red Hat Display", 'Google Sans', sans-serif;
    color: white;
    font-weight: 700;
    position: relative;
    z-index: 1;
}

.dashboard-battery-widget-container-inner::after {
    content: "";
    width: 50px;
    height: 300px;
    position: absolute;
    z-index: 1;
    right: -20px;
    background: #8bc34a;
    clip-path: path('M33.581 -38L38.0185 -21.3333C42.5393 -4.66667 51.2893 28.6667 48.0185 62C44.6227 95.3333 29.206 128.667 23.581 162C17.956 195.333 22.5393 228.667 24.6852 245.333L26.9143 262H0.247668V245.333C0.247668 228.667 0.247668 195.333 0.247668 162C0.247668 128.667 0.247668 95.3333 0.247668 62C0.247668 28.6667 0.247668 -4.66667 0.247668 -21.3333V-38H33.581Z');
    animation: wave 7s ease infinite;
}


.dashboard-battery-widget-container-inner::before {
    content: "";
    width: 25px;
    height: 116px;
    position: absolute;
    z-index: 1;
    right: -24px;
    background: #8bc34a;
    opacity: 0.8;
    clip-path: path('M16.4222 0L15.5603 9.66667C14.7306 19.3333 12.9583 38.6667 13.4175 58C13.8444 77.3333 16.4222 96.6667 17.7111 106.333L19 116H0.955555V106.333C0.955555 96.6667 0.955555 77.3333 0.955555 58C0.955555 38.6667 0.955555 19.3333 0.955555 9.66667V0H16.4222Z');
    animation: swell 4s ease infinite;
}

.dashboard-battery-widget-container-inner.red,
.dashboard-battery-widget-container-inner.red::after,
.dashboard-battery-widget-container-inner.red::before {
    background: #f44336;
}

@keyframes wave {

    0% {
        top: 0;
        right: -20px;
    }

    50% {
        top: -140px;
        right: -40px;
    }

    100% {
        top: 0;
        right: -20px;
    }

}

@keyframes swell {

    0%,
    100% {
        transform: translate(-10px, 0);
    }

    50% {
        transform: translate(-2px, 0);
    }
}



.dashboard-battery-numbers {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    width: 100%;
}

.dashboard-battery-widget-box {
    width: 33%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
}

.dashboard-battery-widget-box img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    filter: invert(1);
    opacity: 0.5
}

.dashboard-battery-widget-box-text {
    padding-left: 6px;
}

.dashboard-battery-widget-box-key {
    font-weight: 300;
    color: white;
    font-size: 13px;
}

.dashboard-battery-widget-box-key {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.dashboard-battery-widget-box-key img {
    margin-left: 5px;
    width: 12px;
    height: 12px;
    opacity: 1;
}

.dashboard-battery-widget-box-value {
    font-weight: 800;
    color: white;
    font-size: 15px;
    margin-top: 2px;
    font-family: "Red Hat Display", 'Google Sans', sans-serif;
}

.dashboard-battery-widget-box-value.green {
    color: #8bc34a;
    text-shadow: 0 0 10px #8bc34a;
}

.dashboard-battery-widget-box-value.red {
    color: #ffa9a2;
}


@media screen and (max-width: 1250px) {

    .full-container .dashboard-top-angle-widget-text {
        font-size: 12px;
    }

    .full-container .dashboard-top-angle-widget-value {
        font-size: 16px;
    }

}

@media screen and (max-width: 1100px) {

    /* TOP WIDGET */
    .dashboard-top-widget {
        flex-direction: column;
    }

    .dashboard-battery-widget {
        width: 100%;
    }

    .dashboard-top-numbers-widget {
        width: calc(100% - 40px);
        margin-left: 40px;
    }

    .dashboard-top-angle-widget-text {
        font-size: 11px !important;
    }

    .dashboard-top-angle-widget-value {
        font-size: 13px !important;
    }

}



@media screen and (max-width: 600px) {
    .dashboard-top-instuments-widget {
        flex-direction: column;
        width: 100%;
        margin-top: 20px;
    }

    .dashboard-top-instument-note,
    .dashboard-top-instument-activity {
        margin: 10px;
        width: 100%;
    }

    .dashboard-battery-numbers {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .dashboard-battery-widget-box {
        width: 100%;
        justify-content: flex-start;
        align-items: center;
    }

    .dashboard-battery-widget-layout::after {
        font-size: 20px;
    }

}






/* GAMES */
.dashboard-games {
    width: 100%;
    margin-top: 20px;
}

.dashboard-games h2 {
    color: white;
    padding-left: 10px;
    text-transform: uppercase;
    font-weight: 1000;
    margin-bottom: 10px;
}

.dashboard-games-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.dashboard-games-header-types {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    background: rgba(0, 0, 0, 0.2);
    height: 40px;
    margin-top: -8px;
    border-radius: 10px;
}

.dashboard-games-header-type {
    color: white;
    font-size: 14px;
    padding: 0 20px;
    width: 80px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.dashboard-games-header-type.active {
    background: #e91e63;
    font-weight: bold;
}

.dashboard-games-header-type:nth-child(2).active {
    background: #f44336;
}

.dashboard-games-header-type:last-child.active {
    background: #ff5722;
}

/* POPULAR WIDGET */
.dashboard-popular-widget {
    margin: 10px;
    width: calc(50% - 20px);
    background-image: linear-gradient(to right, #3f51b5, #3a4698, #333b7d, #2d3062, #252649);
    border-radius: 30px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s, box-shadow 0.3s;
    user-select: none;
}

.dashboard-popular-widget:hover {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
    transform: scale(1.01);
}

.dashboard-popular-widget>img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    opacity: 0.07;
    filter: blur(5px);
}

.dashboard-popular-widget-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 2;
}

.dashboard-popular-widget-tags {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-popular-widget-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ff9800;
    padding: 2px 10px;
    height: 30px;
    border-radius: 15px;
    margin-right: 10px;
}

.dashboard-popular-widget-tag.pink {
    background: #e91e63;
}

.dashboard-popular-widget-tag img {
    width: 14px;
    height: 14px;
    object-fit: contain;
    filter: invert(1);
}

.dashboard-popular-widget-tag-text {
    font-family: "Red Hat Display", 'Google Sans', sans-serif;
    font-weight: 500;
    font-size: 13px;
    padding-left: 2px;
    padding-top: 2px;
    color: white;
}

.dashboard-popular-widget-players {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-popular-widget-player {
    margin-left: -10px;
    border: 3px solid #3f51b5;
    border-radius: 18px;
    width: 36px;
    height: 36px;
    position: relative;
}

.dashboard-popular-widget-player-count {
    padding-left: 10px;
    font-size: 14px;
    font-weight: bold;
    color: white;
}

.dashboard-popular-widget-title {
    font-weight: 700;
    font-family: "Red Hat Display", 'Google Sans', sans-serif;
    margin-top: 20px;
    font-size: 30px;
    text-transform: uppercase;
    color: white;
    letter-spacing: 1px;
    position: relative;
    z-index: 2;
}



.dashboard-popular-widget-numbers {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}

.dashboard-popular-widget-box {
    width: 33.33336%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
}

.dashboard-popular-widget-box img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    filter: invert(1)
}

.dashboard-popular-widget-box-text {
    padding-left: 6px;
}

.dashboard-popular-widget-box-key {
    font-weight: 100;
    color: white;
    font-size: 13px;
    display: flex;
}

.dashboard-popular-widget-box-key img {
    opacity: 0.8;
    width: 12px;
    height: 12px;
    object-fit: contain;
    margin-left: 4px;
    margin-top: 4px;
}

.dashboard-popular-widget-box-value {
    font-weight: 800;
    color: white;
    font-size: 15px;
    margin-top: 2px;
}






/* GAME WIDGET */
.dashboard-games-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
}

.dashboard-game-widget {
    width: calc(25% - 20px);
    margin: 10px;
    background-image: linear-gradient(to right, #3f51b5, #3a4698, #333b7d, #2d3062, #252649);
    border-radius: 30px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s, box-shadow 0.3s;
    user-select: none;
}

.dashboard-game-widget.red {
    background-image: linear-gradient(to right, #4e1f1f, #41191a, #351415, #290f0f, #1f0505);
}

.dashboard-game-widget.blue {
    background-image: linear-gradient(to right, #2c354c, #242c44, #1b243b, #131c33, #0b142b);
}

.dashboard-game-widget.yellow {
    background-image: linear-gradient(to right, #605538, #4d442b, #3b331f, #2a2313, #1b1400);
}

.dashboard-game-widget.pink {
    background-image: linear-gradient(to right, #4e2349, #3f1d3b, #30172d, #221120, #150613);
}

.dashboard-game-widget.green {
    background-image: linear-gradient(to right, #255939, #1b4c2e, #113f24, #07331a, #002710);
}

.dashboard-game-widget.tiffany {
    background-image: linear-gradient(to right top, #0c3b39, #175c59, #227f7b, #2da49f, #39cac5);
}

.dashboard-game-widget.terracotta {
    background-image: linear-gradient(to right top, #754832, #914d2c, #ad5125, #ca551d, #e75710);
}

.dashboard-game-widget.purple {
    background-image: linear-gradient(to right top, #09020a, #29112e, #481453, #69127a, #8d01a3);
}


.dashboard-game-widget:hover {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
    transform: scale(1.01);
}

.dashboard-game-widget>img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    opacity: 0.07;
    filter: blur(5px);
}

.dashboard-game-widget-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 2;
}

.dashboard-game-widget-tags {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-game-widget-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ff9800;
    padding: 2px 10px;
    height: 30px;
    border-radius: 15px;
    margin-right: 10px;
}

.dashboard-game-widget-tag.pink {
    background: #e91e63;
}

.dashboard-game-widget-tag.red {
    background: #f44336;
}

.dashboard-game-widget-tag.yellow {
    background: #ff5722;
}

.dashboard-game-widget-tag img {
    width: 14px;
    height: 14px;
    object-fit: contain;
    filter: invert(1);
}

.dashboard-game-widget-tag-text {
    font-family: "Red Hat Display", 'Google Sans', sans-serif;
    font-weight: 500;
    font-size: 13px;
    padding-left: 2px;
    padding-top: 2px;
    color: white;
}

.dashboard-game-widget-players {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-game-widget-player-count {
    padding-left: 10px;
    font-size: 12px;
    font-weight: 400;
    color: white;
    opacity: 0.6;
}

.dashboard-game-widget-title {
    font-weight: 700;
    font-family: "Red Hat Display", 'Google Sans', sans-serif;
    margin-top: 15px;
    font-size: 15px;
    text-transform: uppercase;
    color: white;
    letter-spacing: 1px;
    position: relative;
    z-index: 2;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.dashboard-game-widget-numbers {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.dashboard-game-widget-box {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
}

.dashboard-game-widget-box img {
    width: 16px;
    height: 16px;
    object-fit: contain;
    filter: invert(1);
    opacity: 0.5;
}

.dashboard-game-widget-box-text {
    padding-left: 6px;
}

.dashboard-game-widget-box-key {
    font-weight: 100;
    color: rgba(255, 255, 255, 0.7);
    font-size: 12px;
    display: flex;
}

.dashboard-game-widget-box-key img {
    opacity: 0.8;
    width: 12px;
    height: 12px;
    object-fit: contain;
    margin-left: 4px;
    margin-top: 2px;
}

.dashboard-game-widget-box-value {
    font-weight: 400;
    color: rgba(255, 255, 255, 0.95);
    font-size: 12px;
    margin-top: 2px;
}




@media screen and (max-width: 1250px) {
    .full-container .dashboard-popular-widget {
        width: 100%;
    }

    .full-container .dashboard-game-widget {
        width: calc(50% - 20px);
    }
}


@media screen and (max-width: 600px) {

    .full-container .dashboard-game-widget,
    .full-container .dashboard-popular-widget {
        width: 100%;
        margin: 10px 0;
    }

    .dashboard-games-container {
        margin: 0;
    }

}






/* DASHBOARD FILTER MODAL */
.dashboard-filter-modal {
    outline: none;
    width: 100%;
    max-width: 350px;
    height: 100%;
    max-height: 500px;
    background: white;
    border-radius: 20px;
    position: relative;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    transform: translateY(-100%);
    transition: transform 200ms ease-in-out, max-width 0.3s;
    overflow-x: hidden;
    overflow-y: auto;
}

.dashboard-filter-modal.period {
    max-width: 800px;
}

.ReactModal__Overlay.dashboard-filter-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    padding: 30px 40px
}

.ReactModal__Overlay--after-open.dashboard-filter-overlay {
    opacity: 1;
}

.ReactModal__Overlay--before-close.dashboard-filter-overlay {
    opacity: 0;
}

.ReactModal__Overlay--after-open .dashboard-filter-modal {
    transform: translateY(0);
}

.ReactModal__Overlay--before-close .dashboard-filter-modal {
    transform: translateY(-100%);
}

.dashboard-filter-header {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d7d7d7;
    padding: 0 20px;
}

.dashboard-filter-header h2 {
    font-weight: 800;
    font-size: 18px;
}

.dashboard-filter-close {
    border: 1px solid #9F9F9F;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.dashboard-filter-close img {
    width: 10px;
    height: 10px;
    object-fit: contain;
}

.dashboard-filter-container {
    height: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.dashboard-filter-container-inner {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 10px;
}


.dashboard-filter-bottom {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 60px;
    border-top: 1px solid #ddd;
    padding: 0 20px;
}

.dashboard-filter-button {
    background: #03a9f4;
    margin-left: 15px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    border-radius: 10px;
    font-size: 14px;
    color: white;
    font-weight: bold;
    transition: box-shadow 0.4s;
    cursor: pointer;
}

.dashboard-filter-button:hover {
    box-shadow: 0 0 10px #03a9f4;
}

.dashboard-filter-button.cancel {
    background: #bababa;
}

.dashboard-filter-button.cancel:hover {
    box-shadow: 0 0 10px #999;
}

.dashboard-filter-dates {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 10px;
    height: 100%;
    max-width: 350px;
}

.dashboard-filter-date {
    height: 45px;
    border: 1px solid #e2e2e2;
    padding: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin: 5px 10px;
    border-radius: 10px;
    width: calc(100% - 20px);
}

.dashboard-filter-date.active {
    background: #3f51b5;
    border-color: #3f51b5;
    color: #fff;
    font-weight: bold;
    transition: background-color 0s;
}

.dashboard-filter-date.active:hover {
    background: #3f51b5;
}

.dashboard-filter-date:hover {
    background: #eaeaea;
}


/* Period */
.dashboard-filter-period-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 400px;
}

.dashboard-filter-period-title {
    font-size: 14px;
    text-align: center;
    margin-bottom: 10px;
    font-weight: bold;
}

.dashboard-filter-period {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.dashboard-filter-period span {
    margin-top: 10px;
    padding: 0 10px;
    margin-right: 5px;
}

.dashboard-filter-period input {
    width: 120px;
    height: 40px;
    padding-left: 15px;
    border: 1px solid #c2c2c2;
    border-radius: 10px;
    margin-right: 5px;
}


@media screen and (max-width: 600px) {

    .dashboard-filter-modal {
        max-height: 100%;
    }

    .ReactModal__Overlay.dashboard-filter-overlay {
        padding: 20px
    }

    .dashboard-filter-modal.period {
        max-width: 350px;
    }

    .dashboard-filter-period-container {
        width: 100%;
        padding-top: 20px;
    }

    .dashboard-filter-container-inner {
        flex-direction: column;
    }

    .dashboard-filter-period {
        flex-direction: column;
    }

    .dashboard-filter-period input {
        width: 200px;
        height: 50px;
        margin-bottom: 10px;
    }

    .dashboard-filter-period span {
        display: none;
    }

    .dashboard-popular-widget-title {
        font-size: 15px;
    }

    .dashboard-popular-widget-numbers {
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: 5px;
    }

    .dashboard-popular-widget-head {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .dashboard-popular-widget-players {
        padding-left: 10px;
        padding-top: 10px;
    }

    .dashboard-popular-widget-box {
        width: 50%;
        align-items: center;
        margin-top: 10px;
    }

    .dashboard-popular-widget-box img {
        width: 18px;
        height: 18px;
    }

    .dashboard-popular-widget-box-text {
        padding-left: 6px;
    }

    .dashboard-popular-widget-box-key {
        font-size: 12px;
    }

    .dashboard-popular-widget-box-value {
        font-size: 13px;
        margin-top: 2px;
    }


}


.dashboard-empty-modal-text {
    text-align: center;
    padding: 50px;
    font-size: 14px;
}




.pdfPage {
    background: #fff;
    padding: 20px;
}

.pdfPage h1 {
    text-align: center;
    font-size: 28px;
}

.pdfPage h2 {
    text-align: center;
    font-size: 15px;
    font-weight: normal;
}

.pdfPageGame {
    margin-top: 40px;
}

.pdfPageGame table {
    width: 100%;
    border-collapse: collapse;
}

.pdfPageGame table td {
    padding: 5px 10px;
    font-size: 14px;
}

.pdfPageText {
    font-size: 14px;
}

.pdfPageText span {
    font-weight: bold;
}