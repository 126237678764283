.monitors {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
}

.monitor {
    width: calc(33.33336% - 20px);
    background: #fff;
    height: 200px;
    margin: 0 10px 20px 10px;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 0 20px #333;
    transition: transform 0.5s, box-shadow 0.4s;
}

.monitor:hover {
    transform: scale(1.03);
    box-shadow: 0 0 40px #111;
}

.monitor-head {
    height: 30px;
    background: #dfdfdf;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
}

.monitor-head-text {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 900;
    font-family: "Red Hat Display", sans-serif;
    color: #7b7c8a;
}

.monitor-head-dots {
    display: flex;
    justify-content: center;
    align-items: center;
}

.monitor-head-dot {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: black;
    margin: 0 2px;
}

.monitor-head-dot.green {
    background: #28c840;
}

.monitor-head-dot.red {
    background: #ff5f57;
}

.monitor-head-dot.yellow {
    background: #febc2e;
}


.monitor-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 170px;
    padding: 0 10px 20px 10px;
}

.monitor-name {
    font-weight: 900;
    font-size: 16px;
    color: #131313;
    text-align: center;
}

.monitor-url {
    font-size: 14px;
    color: #03a9f4;
    border-bottom: 1px solid #03a9f4;
    margin-top: 5px;
    text-align: center;
}

.monitor-token {
    font-size: 12px;
    color: #999;
    margin-top: 5px;
    text-align: center;
}


@media screen and (max-width: 1200px) {
    .monitor {
        width: calc(50% - 20px);
    }
}

@media screen and (max-width: 600px) {
    .monitor {
        width: calc(100% - 20px);
    }
}