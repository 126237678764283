
/* TABLE MODAL CONTENT */
.table--modal-content {
    height: calc(100vh - 50px);
    overflow-y: scroll;
    padding: 20px;
}

.table--modal-content::-webkit-scrollbar {
    display: none;
}


/* LAYOUT STYLES */
.table--layout-box {
    height: calc(100vh - 90px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.table--layout-box span {
    color: #222;
    font-size: 30px;
}

.table--layout-box img {
    width: 80px;
    height: 80px;
    object-fit: contain;
    filter: invert(1);
}

.table--layout-box h2 {
    font-weight: 1000;
    color: #222;
    font-size: 20px;
    margin-top: 20px;
    text-align: center;
}

.table--layout-box p {
    font-size: 14px;
    color: #666;
    font-weight: 200;
    margin-top: 5px;
    text-align: center;
}

.table--layout-box .button {
    background: #03a9f4;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    border-radius: 15px;
    color: white;
    font-weight: 800;
    cursor: pointer;
    transition: transform 0.3s;
    width: 200px;
    font-size: 14px;
}

.table--layout-box .button:hover {
    transform: scale(1.05);
}




/* DELAER STYLES */
.table--dealer-item {
    min-height: 50px;
    padding: 10px 0;
    border-bottom: 1px solid #eaeaea;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.table--dealer-item-text {
    width: 100%;
    max-width: 400px;
}

.table--dealer-item-text h2 {
    font-size: 16px;
    font-weight: 800;
}

.table--dealer-item-text p {
    font-size: 12px;
    font-weight: 200;
    color: #222
}




/* SETTINGS STYLE */
.table--settings {
    height: calc(100vh - 120px);
}

.table---settings-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px 5px;
}

.table---settings-row span {
    font-size: 14px;
    font-weight: 800;
}

.table---settings-element {
    min-height: 50px;
    padding: 5px 15px;
    background: #efefef;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.table---settings-element p {
    font-size: 13px;
    font-weight: 300;
    color: #222
}

.table---settings-element span {
    font-size: 12px;
    font-weight: bold;
}

.table---settings-element img {
    width: 16px;
    height: 16px;
    object-fit: contain;
    position: absolute;
    right: 15px;
    opacity: 0.5;
}

.table--settings-bottom {
    position: fixed;
    width: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    background: white;
    border-top: 1px solid #d7d7d7;
    padding: 10px 20px;
    box-shadow: 0 -5px 10px #eaeaea;
}

.table--settings-button {
    background: #03a9f4;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    color: white;
    font-weight: 800;
    cursor: pointer;
    transition: transform 0.3s;
}

.table--settings-button:hover {
    transform: scale(1.05);
}

.table--settings-remove {
    color: #f44336;
    font-weight: 900;
    font-size: 14px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
}





/* CURRENCY STYLES */
.table--currency-item {
    padding: 10px 0;
    border-bottom: 1px solid #eaeaea;
}

.table--currency-item-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.table--currency-item-text {
    max-width: 400px;
    width: 100%;
    padding-right: 10px;
}

.table--currency-item-text p {
    font-weight: 800;
    font-size: 15px;
}

.table--currency-item-text span {
    font-size: 12px;
    margin-top: -3px;
    display: block;
}

.table--currency-element {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.table--currency-element p {
    font-size: 13px;
    font-weight: 400;
    margin-right: 10px;
    color: #222
}

.table--currency-element p::after {
    content: ":"
}

.table--currency-element span {
    font-weight: 700;
    font-size: 14px;
}


.table--currency-item-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.table--currency-edit-button {
    width: 30px;
    height: 30px;
    border-radius: 20px;
    background: #03a9f4;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 5px;
}

.table--currency-edit-button img {
    width: 13px;
    height: 13px;
    object-fit: contain;
}

.table--currency-remove-button {
    width: 30px;
    height: 30px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: #f44336;
}

.table--currency-remove-button img {
    width: 13px;
    height: 13px;
    object-fit: contain;
    filter: invert(1);
}


.table--currency-create-button {
    background: #03a9f4;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    border-radius: 25px;
    color: white;
    font-weight: 800;
    cursor: pointer;
    transition: transform 0.3s;
}

.table--currency-create-button:hover {
    transform: scale(1.05);
}




/* MESSAGE STYLES */
.table--message-textarea {
    width: 100%;
    height: 150px;
    border-radius: 10px;
    background: white;
    border: 1px solid #dedede;
    padding: 15px 20px;
    font-size: 14px;
    outline: none;
    caret-color: #0277bd;
    transition: border-color 0.4s;
}

.table--message-textarea:focus {
    border-color: #0277bd;
}

.table--message-button {
    background: #03a9f4;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    border-radius: 25px;
    color: white;
    font-weight: 800;
    cursor: pointer;
    transition: transform 0.3s;
}

.table--message-button:hover {
    transform: scale(1.05);
}





.table--modal-messages {
    margin-top: 20px;
}

.table--modal-message-loading {
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table--modal-message-loading span {
    font-size: 20px;
}

.table--modal-message {
    border-bottom: 1px solid #e0e0e0;
    padding: 10px 0;
}

.table--modal-message-name {
    font-size: 14px;
    font-weight: bold;
    color: #ff9800
}

.table--modal-message-text {
    font-size: 14px;
    padding: 5px 0;
    color: #222;
    overflow: hidden;
    overflow-wrap: anywhere;
    white-space: normal;
}

.table--modal-message-datetime {
    font-size: 13px;
    color: #999;
}


