.process--container {
    padding: 20px;
}

.process--container-title {
    font-family: "Red Hat Display", sans-serif;
    text-align: center;
    font-weight: 1000;
    color: #b9bbce;
    font-size: 20px;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.process--box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    margin-left: -125px;
}

.process--box-number {
    font-family: "Red Hat Display", sans-serif;
    font-weight: 1000;
    font-size: 40px;
    color: #b9bbce;
    width: 130px;
    text-align: center;
}

.process--box-element {
    background-image: linear-gradient(to right bottom, #3a3a3a, #303030, #262626, #1d1d1d, #141414);
    margin: 0 7px;
    height: 120px;
    width: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    box-shadow: 0 0 20px #666;
}

.process--box-title {
    font-family: "Red Hat Display", sans-serif;
    color: white;
    font-weight: 1000;
    font-size: 16px;
    text-transform: uppercase;
}

.process--box-title.ante {
    color: #03a9f4;
    text-shadow: 0 0 5px #03a9f4;
}

.process--box-title.bet {
    color: #8bc34a;
    text-shadow: 0 0 5px #8bc34a;
}

.process--box-title.insurance {
    color: #e91e63;
    text-shadow: 0 0 5px #e91e63;
}

.process--box-title.purchase {
    color: #9c27b0;
    text-shadow: 0 0 5px #9c27b0;
}

.process--box-title.sixth,
.process--box-title.fifth,
.process--box-title.exchange {
    color: #ffc107;
    text-shadow: 0 0 5px #ffc107;
}

.process--box-value {
    font-size: 20px;
    font-weight: bold;
    font-family: "Red Hat Display", sans-serif;
    color: white;
    font-weight: 1000;
}

.process--box-reason {
    font-size: 12px;
    font-family: "Red Hat Display", sans-serif;
    color: white;
    font-weight: 400;
    margin-top: 10px;
}


.process--box-datetime {
    font-size: 10px;
    font-family: "Red Hat Display", sans-serif;
    color: white;
    font-weight: 400;
}

.process--box-datetime span {
    font-weight: 200;
}



@media screen and (max-width: 900px) {

    .process--boxes {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
    }

    .process--box {
        width: 50%;
        flex-direction: column;
        margin-left: 0;
        padding: 0 10px;
    }

    .process--box-element {
        margin-bottom: 10px;
        width: 100%;
    }
}