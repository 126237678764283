* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Nunito Sans', sans-serif, -apple-system, BlinkMacSystemFont;
    -webkit-tap-highlight-color: transparent;
    background-color: linear-gradient(to left, #2e2e48, #2a2a45, #272742, #232340, #20203d);;
}

html,
body,
#root {
    min-height: 100%;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
}

.background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-image: linear-gradient(to left, #2e2e48, #2a2a45, #272742, #232340, #20203d);
}

.background img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.3s;
}

.background .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(39, 39, 66, 0.8);
}

.app {
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 50px;
}

.container {
    width: calc(100% - 300px);
    min-height: 100%;
    transition: width 0.3s;
    overflow-x: hidden;
}

.container.minimized {
    width: calc(100% - 100px);
}

.loading {
    height: 100%;
    background-image: linear-gradient(to left, #2e2e48, #2a2a45, #272742, #232340, #20203d);
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading span {
    font-size: 30px;
    color: white
}



.notification-popup {
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
}

.notification {
    color: white;
    font-size: 14px;
    display: flex;
}

.notification-html {
    margin-right: 20px !important;
    margin-left: 0 !important;
}

.notification-icon {
    font-size: 6px;
    margin-right: 10px !important;
    margin-left: 0 !important;
}


@media screen and (max-width: 900px) {

    .container,
    .container.minimized {
        width: 100%;
    }
}



.swal-form {
    display: flex;
    flex-direction: column;
}

.swal-form input,
.swal-form select {
    width: 100%;
    height: 50px;
    border-radius: 25px;
    background: white;
    border: 1px solid #dedede;
    padding: 0 25px;
    font-size: 14px;
    outline: none;
    caret-color: #0277bd;
    transition: border-color 0.4s;
    margin: 10px 0
}

.swal-form input:focus {
    border-color: #0277bd;
}



.avatar {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    overflow: hidden;
    background: #ebecf2;
    position: relative;
}

.avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.4s;
}

.avatar.default img {
    width: 60%;
    height: 60%;
    object-fit: cover;
    opacity: 0.5;
}

.avatar img.default {
    width: 60%;
    height: 60%;
    object-fit: cover;
    opacity: 0.5;
    position: absolute;
}

.main-loading {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(to left, #2e2e48, #2a2a45, #272742, #232340, #20203d);
}


@media screen and (min-width: 600px) {
    .main-loading div {
        width: calc(100% / 3);
    }
}