/*
    ELECTRON EUROPEAN POKER STYLES
*/


/* GAME HEADER STYLES */
.eep--game-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.eep--game-header h1 {
    font-weight: 1000;
    color: white;
    font-size: 24px;
}

.eep-game-header-connection {
    display: flex;
    justify-content: center;
    align-items: center;
}

.eep-game-header-connection p {
    font-size: 12px;
    color: white;
    font-weight: 400;
}


.eep-game-header-connection p::after {
    content: "•";
    font-size: 12px;
    margin-left: 6px;
    color: white;
    font-weight: 400;
}

.eep-game-header-connection-status {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    color: white;
    font-weight: 400;
    font-size: 12px;
}

.eep-game-header-connection-status span {
    margin-right: 4px;
}

.eep-game-header-connection-status img {
    width: 12px;
    height: 12px;
    object-fit: contain;
    margin-right: 4px;
}

.eep-game-header-connection-status.connected {
    font-weight: 800;
}

.eep-game-header-connection-status.disconnected {
    color: #f44336;
    font-weight: 800;
}




@media screen and (max-width: 900px) {

    .eep--game-header {
        height: 50px;
        display: flex;
        justify-content: center;
    }

    .eep--game-header h1 {
        font-size: 14px;
    }

    .eep-game-header-connection {
        flex-direction: column;
        align-items: flex-start;
    }

    .eep-game-header-connection p::after {
        display: none;
    }

    .eep-game-header-connection-status {
        margin-left: 0;
    }

}




/* GAME MENU STYLES */
.eep--game-menu {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.eep--game-menu-item {
    padding: 6px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 8px;
    border-radius: 8px;
    transition: background-color 0.3s;
}

.eep--game-menu-item:hover {
    background: #03a9f4;
}

.eep--game-menu-item span {
    font-size: 14px;
    margin-left: 5px;
    color: white;
    font-weight: 700;
}

.eep--game-menu-item img {
    width: 14px;
    height: 14px;
    object-fit: contain;
}

.eep--game-menu-item.video {
    background: #f44336;
}


@media screen and (max-width: 900px) {

    .eep--game-menu {
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        justify-content: flex-start;
        margin: 20px -20px 0 -20px
    }

    .eep--game-menu::-webkit-scrollbar {
        display: none;
    }

    .eep--game-menu-item.video {
        margin-right: 20px;
    }

}


/* GAME LIST */
.eep--game-list {
    background: white;
    padding: 20px;
    border-radius: 20px;
    min-height: calc(100vh - 160px);
    margin-top: 20px;
    margin-bottom: 150px;
}

.eep--content-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 800;
    border-bottom: 1px solid #eaeaea;
    padding: 4px 10px;
}

.eep--content-element {
    padding: 4px 8px;
}

.eep--content-element.last {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.eep--content-element.last .arrow {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background: #eaeaea;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 0 10px #aaa;
}

.eep--content-element.last .arrow img {
    width: 12px;
    height: 12px;
    object-fit: contain;
}

.eep--content-element .link {
    color: #060606;
    font-weight: 800;
    cursor: pointer;
}

.eep--content-element.number {
    width: 5%;
}

.eep--game-content {
    margin: 0 -10px
}

.eep--content-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    border-bottom: 1px solid #eaeaea;
    cursor: pointer;
    border-radius: 5px;
    padding: 3px 10px;
    transition: background-color 0.3s;
}

.eep--content-row:hover {
    background: #f0f0f0;
}


.eep--content-element.more {
    width: 5%;
    position: relative;
}

.eep--more-dropdown-menu-icon {
    width: 50px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.eep--more-dropdown-menu-icon img {
    width: 26px;
    height: 26px;
    object-fit: contain;
}

.eep--more-dropdown-menu {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    z-index: 1;
    right: 0;
    background: white;
    padding: 10px 15px;
    border: 1px solid #FAFAFA;
    box-shadow: 0 0 10px #BBB;
    min-width: 200px;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s linear 0.2s, opacity 0.2s linear;
    border-radius: 15px;
}

.eep--more-dropdown-menu::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: -5px;
    right: 19px;
    width: 16px;
    height: 16px;
    background: white;
    border-radius: 3px;
    transform: rotate(45deg);
}

.eep--content-element.more:hover .eep--more-dropdown-menu {
    transition: visibility 0s, opacity 0.2s linear;
    visibility: visible;
    opacity: 1;
}



.eep--more-dropdown-menu-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 40px;
    padding: 5px 0;
    cursor: pointer;
    width: 100%;
    border-bottom: 1px solid #dedede;
}

.eep--more-dropdown-menu-item img {
    width: 16px;
    height: 16px;
    object-fit: contain;
}

.eep--more-dropdown-menu-item span {
    margin-left: 5px;
    color: #232323
}

.eep--more-dropdown-menu-item.red {
    border-bottom: none;
}

.eep--more-dropdown-menu-item.red span {
    color: #f44336;
    font-weight: 600;
}



@media screen and (max-width: 900px) {

    .eep--content-head {
        display: none;
    }

    .eep--content-row {
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .eep--content-element {
        width: 100% !important;
    }

    .eep--content-element.more,
    .eep--content-element.number {
        display: none;
    }

}






/* GAME FILTER */
.eep--game-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 20px 20px 15px 20px;
    border-bottom: 1px solid #cccccc;
    margin: -20px -20px 20px -20px;
}

.eep--number-filter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.eep--number-filter form {
    display: flex;
}

.eep--number-filter-box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.eep--number-filter input {
    height: 40px;
    width: 240px;
    border-radius: 20px;
    padding-left: 15px;
    padding-right: 50px;
    border: 1px solid #eaeaea;
    outline: none;
}

.eep--number-filter button {
    padding-top: 2px;
    background: #03a9f4;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-left: -40px;
    border: none;
    outline: none;
    cursor: pointer;
}

.eep--number-filter button img {
    width: 16px;
    height: 16px;
    object-fit: contain;
}

.eep--date-filter {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.eep--date-filter span {
    font-size: 13px;
    padding-right: 3px;
    color: #555
}

.eep--date-filter input {
    width: 103px;
    height: 40px;
    padding-left: 15px;
    border: 1px solid #eaeaea;
    border-radius: 20px;
    margin-right: 5px;
}

.eep--date-filter>button {
    background: #03a9f4;
    width: 120px;
    height: 40px;
    border-radius: 20px;
    border: none;
    outline: none;
    cursor: pointer;
    color: white;
    font-weight: bold;
}

.react-datepicker__day--in-range {
    background: #03a9f4 !important;
}

.eep--number-mobile-filter {
    background: #607D8B;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    border-radius: 20px;
    display: none;
}

.eep--number-mobile-filter img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    filter: invert(1);
}


@media screen and (max-width: 900px) {

    .eep--game-filter {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-bottom: 0;
        border-bottom: 1px solid #e1e1e1;
    }

    .eep--number-filter input {
        width: 100%;
    }

    .eep--number-filter form {
        width: 100%;
    }

    .eep--number-filter {
        width: calc(100% - 100px);
    }

    .eep--number-filter-box {
        margin-bottom: 10px;
    }

    .eep--number-mobile-filter {
        display: flex;
    }

    .eep--date-filter {
        flex-direction: column;
        width: calc(100% + 40px);
        margin-left: -20px;
        margin-right: -20px;
        padding: 0 20px;
        height: 160px;
        overflow: hidden;
        transition: height 0.4s;
        background: #f1f1f1;
    }

    .eep--date-filter.disabled {
        height: 0;
    }

    .eep--date-filter .react-datepicker-wrapper {
        width: 100%;
    }

    .eep--date-filter span {
        display: none;
    }

    .eep--date-filter input {
        width: 100%;
        margin-top: 10px;
    }

    .eep--date-filter>button {
        width: 100%;
        margin-top: 10px;
    }

}


/* GAME MODAL */
.eep--modal {
    outline: none;
    margin: 0;
    width: 100%;
    height: 100%;
    max-width: 500px;
    background: white;
    border-radius: 20px 0 0 20px;
    position: relative;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    transform: translateX(500px);
    transition: transform 200ms ease-in-out;
    overflow-y: scroll;
    overflow-x: hidden;
}

.eep--modal.eep--modal-game {
    max-width: 1200px;
}

.eep--modal.eep--modal-statistics {
    max-width: 100%;
    transform: translateY(-100vh);
    border-radius: 20px;
}

.ReactModal__Overlay.eep--overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 4;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    backdrop-filter: blur(5px);
    opacity: 0;
    transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay.eep--overlay.eep--overlay-statistics {
    justify-content: center;
    padding: 20px 40px;
}

.ReactModal__Overlay--after-open.eep--overlay {
    opacity: 1;
}

.ReactModal__Overlay--before-close.eep--overlay {
    opacity: 0;
}

.ReactModal__Overlay--after-open .eep--modal {
    transform: translateX(0);
}

.ReactModal__Overlay--before-close .eep--modal {
    transform: translateX(500px);
}

.ReactModal__Overlay--before-close .eep--modal.eep--modal-game {
    transform: translateX(1200px);
}

.ReactModal__Overlay--after-open .eep--modal.eep--modal-statistics {
    transform: translateY(0);
}

.ReactModal__Overlay--before-close .eep--modal.eep--modal-statistics {
    transform: translateY(-100vh);
}

@media screen and (max-width: 1050px) {
    .ReactModal__Overlay.eep--overlay.eep--overlay-statistics {
        padding: 10px;
    }
}

.eep--modal-header {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d7d7d7;
    padding: 0 20px;
}

.eep--modal-header h2 {
    font-weight: 800;
    font-size: 18px;
}

.eep--modal-close {
    border: 1px solid #9F9F9F;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.eep--modal-close img {
    width: 10px;
    height: 10px;
    object-fit: contain;
}


/* INNER GAME STYLES */
.eep--game-inner {
    height: calc(100vh - 50px);
    overflow-y: scroll;
}

.eep--game-inner::-webkit-scrollbar {
    display: none;
}

.eep--game-inner .eep--layout-box {
    height: calc(100vh - 150px);
}




/* INFORMATION GAME STYLE */
.eep--game-information-row {
    display: flex;
    border-bottom: 1px solid #e6e6e6;
    padding: 20px 0;
    margin-bottom: 20px;
}

.eep--game-information-element {
    padding: 0 20px;
    width: 25%;
}

.eep--game-information-big-element {
    width: 50%;
}

.eep--game-information-item {
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.eep--game-information-item img {
    width: 24px;
    height: 24px;
    object-fit: contain;
}

.eep--game-information-item-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 10px;
}

.eep--game-information-item-title {
    font-size: 12px;
    color: #777;
}

.eep--game-information-item-text {
    font-size: 13px;
    color: #222;
    font-weight: 1000;
}

.eep--game-information-item-text span {
    font-weight: 500;
    color: #555
}

.eep--game-information-text {
    font-size: 13px;
    color: #666;
    font-family: "Red Hat Display", sans-serif;
    text-transform: uppercase;
    color: #b9bbce;
    font-weight: 1000;
}


@media screen and (max-width: 900px) {

    .eep--game-information-row {
        flex-wrap: wrap;
    }

    .eep--game-information-element {
        width: 50%;
    }

    .eep--game-information-big-element {
        padding: 20px;
        width: 100%;
    }
}



/* GAME ACTION BUTTON */
.eep--game-buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.eep--game-button {
    background: #f44336;
    height: 40px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    font-size: 13px;
    font-weight: 800;
    color: white;
    cursor: pointer;
    transition: box-shadow 0.3s;
    margin: 5px 10px 5px 0;
    text-align: center;
}

.eep--game-button:hover {
    box-shadow: 0 0 20px #f44336;
}

/* Blue */
.eep--game-button.blue {
    background-color: #2196f3;
}

.eep--game-button.blue:hover {
    box-shadow: 0 0 20px #2196f3;
}

/* Gray */
.eep--game-button.gray {
    background-color: #607d8b;
}

.eep--game-button.gray:hover {
    box-shadow: 0 0 20px #607d8b;
}

/* Green */
.eep--game-button.green {
    background-color: #8bc34a;
}

.eep--game-button.green:hover {
    box-shadow: 0 0 20px #8bc34a;
}

/* Darkblue */
.eep--game-button.darkblue {
    background-color: #3f51b5
}

.eep--game-button.darkblue:hover {
    box-shadow: 0 0 20px #3f51b5
}


/* Purple */
.eep--game-button.purple {
    background-color: #9c27b0
}

.eep--game-button.purple:hover {
    box-shadow: 0 0 20px #9c27b0
}


/* Pink */
.eep--game-button.pink {
    background-color: #e91e63
}

.eep--game-button.pink:hover {
    box-shadow: 0 0 20px #e91e63
}

/* Disabled */
.eep--game-button.disabled {
    box-shadow: none !important;
    opacity: 0.3;
    cursor: default;
}