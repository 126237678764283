/* Player Row */
.message-row,
.message-row-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f2f2f2;
    padding: 5px 0;
    width: 100%;
}


/* Player Code */
.message-number-head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 5%;
    padding: 5px 10px;
    font-size: 13px;
    font-weight: bold;
    color: #999
}

.message-number {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 5%;
    padding: 5px 10px;
    font-size: 13px;
    font-weight: bold;
    color: #666
}


/* Player User */
.message-user {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 20%;
    padding: 5px 10px 5px 0;
}

.message-user-head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 20%;
    padding: 5px 10px;
    font-size: 13px;
    font-weight: bold;
}

.message-user-text {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.message-user-name {
    font-size: 14px;
    font-weight: bold;
    color: #222;
}

.message-user-code {
    font-size: 13px;
    margin-top: 2px;
    color: #555
}

/* Player Game */
.message-game-head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 25%;
    padding: 5px 10px;
    font-size: 13px;
    font-weight: bold;
}

.message-game {
    width: 25%;
    padding: 5px 10px;
    font-size: 13px;
    font-weight: bold;
    color: #555
}




/* Currency */
.message-text-head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 10px;
    font-size: 13px;
    font-weight: bold;
    width: 35%;
}

.message-text {
    width: 35%;
    font-size: 14px;
    padding: 5px 10px;
    color: #121212;
    overflow: hidden;
    overflow-wrap: anywhere;
    white-space: normal;
}

/* Date */
.message-date-head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 10px;
    font-size: 13px;
    font-weight: bold;
    width: 10%;
}

.message-date {
    width: 10%;
    padding: 5px 10px;
}
