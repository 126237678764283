.game-video {
    right: 10px;
    bottom: 10px;
    position: fixed;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    transition: right 0.3s;
}

.game-video.closed {
    right: -250px;
}

/* Live Styles */
.game-video-live {
    height: 100px;
    width: 40px;
    background: #f44336;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px 0 0 10px;
}

.game-video-live-text {
    color: white;
    font-size: 20px;
    font-weight: 1000;
    font-family: "Red Hat Display", sans-serif;
    text-transform: uppercase;
    transform: rotate(-90deg);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

.game-video-live-text::after {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: white;
    position: absolute;
    right: -20px;
    animation: game-video-live 1.5s ease-in-out 0s infinite;
}

@keyframes game-video-live {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


.game-video-container {
    background: #ff0;
    width: 250px;
    height: 180px;
    border-radius: 0 10px 10px 10px;
    overflow: hidden;
}

.game-video-container video {
    object-fit: cover !important;
}