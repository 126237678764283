.transaction--container {
    padding: 20px;
}

.transaction--container-title {
    font-family: "Red Hat Display", sans-serif;
    text-align: center;
    font-weight: 1000;
    color: #b9bbce;
    font-size: 20px;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.transaction--boxes {
    display: flex;
    flex-direction: column;
}

.transaction--box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f5f5f5;
    padding: 5px 0;
    cursor: pointer;
    transition: background-color 0.3s;
}

.transaction--box:hover {
    background: #f5f5f5;
}

.transaction--key {
    font-size: 13px;
    width: 5%;
    color: #999;
    font-family: "Red Hat Display", sans-serif;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.transaction--key.head {
    color: #b9bbce;
    font-weight: 1000;
}

.transaction--number {
    font-size: 13px;
    width: 35%;
    color: #555;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "Red Hat Display", sans-serif;
    font-weight: 400;
}

.transaction--number.head {
    color: #b9bbce;
    font-weight: 700;
    font-family: "Nunito Sans", sans-serif;
}

.transaction--name {
    font-size: 13px;
    width: 20%;
    color: #131313;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 400;
}

.transaction--name.head {
    color: #b9bbce;
    font-weight: 700;
    font-family: "Nunito Sans", sans-serif;
}

.transaction--name-box-1 {
    color: #607d8b;
    margin-left: 6px;
    font-size: 10px;
    font-weight: 1000;
    font-family: "Red Hat Display", sans-serif;
}

.transaction--name-box-2 {
    color: #3f51b5;
    margin-left: 6px;
    font-size: 10px;
    font-weight: 1000;
    font-family: "Red Hat Display", sans-serif;
}

.transaction--total {
    font-size: 13px;
    width: 30%;
    color: #555;
    font-family: "Red Hat Display", sans-serif;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 400;
}

.transaction--total.head {
    color: #b9bbce;
    font-weight: 700;
    font-family: "Nunito Sans", sans-serif;
}

.transaction--total img {
    width: 17px;
    height: 17px;
    object-fit: contain;
    margin-right: 10px;
}

.transaction--status {
    font-size: 13px;
    width: 5%;
    color: #555;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.transaction--status.head {
    color: #b9bbce;
    font-weight: 700;
}

.transaction--status .error {
    color: white;
    font-weight: bold;
    text-align: center;
    padding: 3px 6px;
    border-radius: 6px;
    width: 80px;
    font-size: 11px;
    background-color: #f44336;
    text-transform: capitalize;
}

.transaction--status .success {
    color: white;
    font-weight: bold;
    text-align: center;
    padding: 3px 6px;
    border-radius: 6px;
    width: 80px;
    font-size: 11px;
    background-color: #8bc34a;
    text-transform: capitalize;
}


@media screen and (max-width: 900px) {

    .transaction--number,
    .transaction--key {
        display: none;
    }

    .transaction--name {
        width: 55%;
    }

    .transaction--total {
        width: 40%;
    }

    .transaction--box {
        width: calc(100% - 40px);
    }

}




/* TRANSACTION MODAL */
.transaction--modal {
    outline: none;
    width: 100%;
    max-width: 600px;
    height: 100%;
    background: white;
    border-radius: 20px;
    position: relative;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    transform: translateY(-100%);
    transition: transform 200ms ease-in-out;
    overflow-x: hidden;
    overflow-y: auto;
}

.ReactModal__Overlay.transaction--overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    padding: 30px 40px
}

.ReactModal__Overlay--after-open.transaction--overlay {
    opacity: 1;
}

.ReactModal__Overlay--before-close.transaction--overlay {
    opacity: 0;
}

.ReactModal__Overlay--after-open .transaction--modal {
    transform: translateY(0);
}

.ReactModal__Overlay--before-close .transaction--modal {
    transform: translateY(-100%);
}

.transaction--modal-header {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d7d7d7;
    padding: 0 20px;
}

.transaction--modal-header h2 {
    font-weight: 800;
    font-size: 18px;
}

.transaction--modal-close {
    border: 1px solid #9F9F9F;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.transaction--modal-close img {
    width: 10px;
    height: 10px;
    object-fit: contain;
}

.transaction--modal-information {
    padding: 20px;
}

.transaction--modal-information-item {
    margin-bottom: 10px;
}

.transaction--modal-information-text {
    font-size: 14px;
    color: #666;
}

.transaction--modal-information-name {
    font-size: 14px;
    color: #121212;
    font-weight: bold;
}

@media screen and (max-width: 900px) {

    .transaction--modal-header h2 {
        font-size: 14px;
    }

    .ReactModal__Overlay.transaction--overlay {
        padding: 10px;
    }

}