.games-box {
    margin-bottom: 20px;
}

.games {
    display: flex;
    flex-wrap: wrap;
    margin-right: -20px;
}

.games-title {
    color: white;
    margin-bottom: 13px;
    font-size: 18px;
    margin-left: 1px;
    font-weight: 800;
}

.game {
    padding: 20px;
    width: calc(33.3334% - 20px);
    margin-right: 20px;
    margin-bottom: 20px;
    border-radius: 20px;
    min-height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background: #19192f;
    position: relative;
    cursor: pointer;
    transition: box-shadow 0.4s, transform 0.4s;
}

.game.electron {
    background-image: url('/public/images/game/gold.png');
}

.game:hover {
    box-shadow: 0 5px 10px 10px #15152c;
    transform: scale(1.02);
}

.game-info {
    display: flex;
    justify-content: center;
    align-items: center;
}

.game-text {
    padding-left: 12px;
}

.game-info h2 {
    font-size: 15px;
    color: #fff;
}

.game.electron h2 {
    text-shadow: 0 0 10px #555;
    color: white;
    text-transform: uppercase;
    font-weight: 1000;
}

.game-info span {
    font-size: 12px;
    color: #ccc;
}

.game.electron span {
    text-shadow: 0 0 10px #555;
    color: white;
    font-weight: 500;
}

.game-info img {
    width: 30px;
    height: 30px;
    object-fit: contain;
    margin-top: -5px;
}


.game-available {
    color: #8bc34a;
    font-size: 12px;
    font-weight: 1000;
    border-radius: 10px;
}

.game.electron .game-available {
    background: #141414;
    padding: 7px 12px;
    background: #8bc34a;
    color: white;
    margin-bottom: -5px;
}

.game-available-offline {
    color: #f44336
}

.game.electron .game-available .game-available-offline {
    background: #141414;
    padding: 7px 12px;
    background: #f44336;
    color: white;
    margin-bottom: -5px;
}

.game-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 20px;
    bottom: 20px;
    color: white;
    font-size: 12px;
    font-weight: 500;
    border-radius: 15px;
}

.game.electron .game-button {
    text-shadow: 0 0 10px #555;
}

.game-button img {
    width: 12px;
    height: 12px;
    object-fit: contain;
    margin-top: 2px;
    margin-left: 6px;
}

@media screen and (max-width: 1100px) {
    .game {
        width: calc(50% - 20px);
    }
}

@media screen and (max-width: 768px) {
    .game {
        width: calc(100% - 20px);
    }
}