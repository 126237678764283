/* Player Header */
.player-page-header {
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
}

.player-page-header h1 {
    font-size: 28px;
    color: white;
    font-weight: 1000;
}


/* Player Search */
.player-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    margin-top: 4px;
    position: relative;
}

.player-search input {
    height: 36px;
    width: 240px;
    border-radius: 18px;
    padding-left: 38px;
    padding-right: 10px;
    border: 1px solid #eaeaea;
    outline: none;
}

.player-search img {
    position: absolute;
    left: 15px;
    width: 18px;
    height: 18px;
    object-fit: contain;
    opacity: 0.7;
}


/* Player Row */
.player-row,
.player-row-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f2f2f2;
    padding: 5px 0;
    width: 100%;
}

.player-row {
    cursor: pointer;
}

.player-row:hover {
    background: #f2f2f2;
}


/* Player Code */
.player-number-head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 5%;
    padding: 5px 10px;
    font-size: 13px;
    font-weight: bold;
    color: #999
}

.player-number {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 5%;
    padding: 5px 10px;
    font-size: 13px;
    font-weight: bold;
    color: #666
}


/* Player User */
.player-user {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 20%;
    padding: 5px 10px 5px 0;
}

.player-user-head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 20%;
    padding: 5px 10px;
    font-size: 13px;
    font-weight: bold;
}

.player-user-text {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.player-user-name {
    font-size: 14px;
    font-weight: bold;
    color: #222;
}

.player-user-code {
    font-size: 13px;
    margin-top: 2px;
    color: #555
}

.player-user-code span {
    font-weight: bold;
    color: #03a9f4
}

/* Player Game */
.player-game-head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 20%;
    padding: 5px 10px;
    font-size: 13px;
    font-weight: bold;
}

.player-game {
    width: 20%;
    padding: 5px 10px;
    font-size: 13px;
    font-weight: bold;
    color: #555
}

/* Player Operator */
.player-operator-head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 15%;
    padding: 5px 10px;
    font-size: 13px;
    font-weight: bold;
}

.player-operator {
    width: 15%;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: bold;
    color: #555;
}



/* Currency */
.player-currency-head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 10px;
    font-size: 13px;
    font-weight: bold;
    width: 10%;
}

.player-currency {
    width: 10%;
    font-size: 14px;
    padding: 5px 10px;
}

/* Date */
.player-date-head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 10px;
    font-size: 13px;
    font-weight: bold;
    width: 20%;
}

.player-date {
    width: 20%;
    padding: 5px 10px;
}

/* More */
.player-more-head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 10px;
    font-size: 13px;
    font-weight: bold;
    width: 5%;
}

.player-more {
    width: 5%;
    position: relative;
}

.player-more-icon {
    width: 50px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.player-more-icon img {
    width: 22px;
    height: 22px;
    object-fit: contain;
}

.player-more-menu {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    z-index: 1;
    right: 0;
    background: white;
    padding: 10px 15px;
    border: 1px solid #FAFAFA;
    box-shadow: 0 0 10px #BBB;
    min-width: 250px;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s linear 0.2s, opacity 0.2s linear;
    border-radius: 15px;
}


.player-more-menu::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: -5px;
    right: 19px;
    width: 16px;
    height: 16px;
    background: white;
    border-radius: 3px;
    transform: rotate(45deg);
}

.player-more:hover .player-more-menu {
    transition: visibility 0s, opacity 0.2s linear;
    visibility: visible;
    opacity: 1;
}



.player-more-menu-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 40px;
    padding: 5px 0;
    cursor: pointer;
    width: 100%;
}

.player-more-menu-item img {
    width: 16px;
    height: 16px;
    object-fit: contain;
}

.player-more-divider {
    border-bottom: 1px solid #f2f2f2;
}

.player-more-menu-item span {
    margin-left: 5px;
    color: #232323;
    font-size: 14px;
}



@media screen and (max-width: 768px) {

    .player-row-header,
    .player-number {
        display: none;
    }

    .player-row {
        flex-direction: column;
        border-bottom: 1px solid #CCC;
        margin-bottom: 10px;
        position: relative;
    }

    .player-user {
        width: 100%;
    }

    .player-game,
    .player-operator,
    .player-currency {
        width: 100%;
        padding: 3px 0;
    }

    .player-date {
        width: 100%;
        padding: 3px 0;
    }

    .player-more {
        position: absolute;
        right: 0;
        width: 30px;
        height: 30px;
    }

    .player-more-menu {
        right: -22px;
    }

    /* Player Header */
    .player-page-header {
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 50px;
    }

    .player-page-header h1 {
        font-size: 18px;
        margin-top: 13px;
        margin-bottom: 5px;
        padding-left: 5px;
    }

    .player-search {
        margin-left: 0;
        width: 100%;
        margin-top: 10px;
    }

    .player-search input {
        width: 100%;
        font-size: 14px;
    }

}






/* GAME MODAL */
.statistics--modal {
    outline: none;
    width: 100%;
    height: 100%;
    background: white;
    border-radius: 20px;
    position: relative;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    transform: translateY(-100%);
    transition: transform 200ms ease-in-out;
    overflow: hidden;
}

.ReactModal__Overlay.statistics--overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 4;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    backdrop-filter: blur(5px);
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    padding: 30px 40px;
}

.ReactModal__Overlay--after-open.statistics--overlay {
    opacity: 1;
}

.ReactModal__Overlay--before-close.statistics--overlay {
    opacity: 0;
}

.ReactModal__Overlay--after-open .statistics--modal {
    transform: translateY(0);
}

.ReactModal__Overlay--before-close .statistics--modal {
    transform: translateY(-100%);
}

.statistics--modal-header {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d7d7d7;
    padding: 0 20px;
}

.statistics--modal-header h2 {
    font-weight: 800;
    font-size: 18px;
}

.statistics--modal-close {
    border: 1px solid #9F9F9F;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.statistics--modal-close img {
    width: 10px;
    height: 10px;
    object-fit: contain;
}


.statistics--content {
    overflow-y: scroll;
    height: 100%;
    padding-bottom: 100px;
}

/* Statistics tab */
.statistics--tabs {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 20px;
}

.statistics--tab {
    background: #f1f5f8;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    height: 40px;
    padding: 0 15px;
    cursor: pointer;
    border-radius: 10px;
    transition: background-color 0.4s;
    width: 200px;
}

.statistics--tab.active {
    background: #8bc34a;
}

.statistics--tab img {
    width: 16px;
    height: 16px;
    object-fit: contain;
}

.statistics--tab-text {
    padding-left: 5px;
    margin-top: 2px;
    font-size: 14px;
    transition: color 0.3s;
    font-weight: bold;
    color: #555
}

.statistics--tab.active .statistics--tab-text {
    color: white;
}

.statistics--tab.active img {
    filter: invert(1)
}





/* Player Information Styles */
.player-information {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.player-information-widget {
    background: #f1f5f8;
    border: 1px solid #dde9f1;
    padding: 20px;
    margin: 20px 0 20px 20px;
    border-radius: 10px;
    width: 300px;
}

.player-information-widget-user {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 0.5px solid #c2d0d9;
}

.player-information-widget-user-text {
    padding-left: 10px;
}

.player-information-widget-user-name {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: #222;
}

.player-information-widget-user-code {
    font-size: 12px;
    color: #666;
}

.player-information-widget-element {
    min-height: 40px;
    padding: 10px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.player-information-widget-element img {
    width: 18px;
    height: 18px;
    object-fit: contain;
}

.player-information-widget-element-text {
    padding-left: 10px;
}

.player-information-widget-element-name {
    font-size: 12px;
    color: #666
}

.player-information-widget-element-value {
    font-weight: bold;
    font-size: 15px;
    color: #222
}

.player-information-widget-element-value.green {
    color: #8bc34a
}

.player-information-widget-element-value.red {
    color: #f44336
}





/* PLAYER INFORMATIOn GAME STYLES */
.player-information-games {
    width: calc(100% - 360px);
    margin: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}


/* TAB STYLES */
.player-information-games-tabs {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 300px;
}

.player-information-games-tab {
    border-bottom: 1px solid #dde9f1;
    cursor: pointer;
    font-weight: bold;
    color: #333;
    margin-right: 10px;
    width: 290px;
    padding: 10px 15px;
    border-radius: 5px;
}

.player-information-games-tab.active {
    background: #3f51b5;
    color: white;
}

.player-information-games-tab-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.player-information-games-tab-name {
    font-size: 14px;
    font-weight: bold;
    margin-top: 5px;
}

.player-information-games-tab-inner {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0;
}

.player-information-games-tab-inner img {
    width: 14px;
    height: 14px;
    object-fit: contain;
}

.player-information-games-tab.active .player-information-games-tab-inner img {
    filter: invert(1);
}

.player-information-games-tab-value {
    font-size: 12px;
    padding-left: 5px;
    font-weight: normal;
}

.player-information-games-tab-value.red {
    color: #f44336;
    font-weight: bold;
}

.player-information-games-tab-value.green {
    color: #8bc34a;
    font-weight: bold;
}

.player-information-games-tab.active .player-information-games-tab-value.green,
.player-information-games-tab.active .player-information-games-tab-value.red {
    color: #fff
}




/* GAME CONTENT */
.player-content {
    width: calc(100% - 300px);
    padding-left: 40px;
}

.player-content-title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
}

.player-content-text {
    font-size: 14px;
    text-align: center;
}

.player-content-widget {
    background: #f1f5f8;
    border: 1px solid #dde9f1;
    display: flex;
    flex-wrap: wrap;
    margin: 15px 0;
    border-radius: 5px;
}

.player-content-widget.dark {
    background: #444;
    border: 1px solid #222;
    color: white;
}

.player-content-widget-box {
    width: 33%;
    display: flex;
    align-items: center;
    padding: 10px;
}

.player-content-widget-box img {
    width: 22px;
    height: 22px;
    object-fit: contain;
}

.player-content-widget.dark .player-content-widget-box img {
    filter: invert(1)
}


.player-content-widget-text {
    padding-left: 10px;
}

.player-content-widget-title {
    font-size: 12px;
}

.player-content-widget-value {
    font-size: 14px;
    font-weight: bold;
}

.player-content-widget-value.red {
    color: #f44336;
    font-weight: bold;
}

.player-content-widget-value.green {
    color: #8bc34a;
    font-weight: bold;
}




/* Combinations */
.player-information-combinations {
    padding: 20px 0 20px 20px;
    margin: 20px 0;
    background: #f1f5f8;
    border: 1px solid #dde9f1;
    border-radius: 5px;
}

.player-information-combinations h3 {
    font-size: 17px;
    margin-bottom: 6px;
}

.player-information-combination-container {
    display: flex;
    flex-wrap: wrap;
}

.player-information-combination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 0;
    width: calc(50% - 20px);
    margin-right: 20px;
}

.player-information-combination-name {
    font-size: 13px;
    font-weight: 500;
    color: #555
}

.player-information-combination-value {
    font-size: 13px;
    color: #555
}





/* PLAYER STATISTICS */
.player-information-chart {
    padding: 30px 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.player-information-chart>span {
    position: absolute;
    font-size: 30px;
    z-index: 1;
    color: #999;
    margin-bottom: 20px;
}



/* HEAAD */
.player-content-statistics-head {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.player-content-statistics-title {
    font-size: 18px;
    font-weight: bold;
    color: #141414;
}

.player-content-statistics-head-filter {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.player-content-statistics-head-filter-item {
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #c1c1c1;
    border-radius: 5px;
    margin-left: 10px;
    height: 30px;
    font-size: 13px;
    color: #222;
    cursor: pointer;
}

.player-content-statistics-head-filter-item.active {
    background: #03a9f4;
    border-color: #03a9f4;
    color: white;
    font-weight: bold;
}


/* Filter */
.player-content-statistics-filter {
    padding: 10px 0;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.player-content-statistics-filter-prev {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background: #e7e7e7;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
    cursor: pointer;
    padding-right: 2px;
    margin-right: 15px;
}

.player-content-statistics-filter-prev:hover {
    background: #d0d0d0;
}

.player-content-statistics-filter-prev img {
    width: 13px;
    height: 13px;
    object-fit: contain;
}

.player-content-statistics-filter-next {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background: #e7e7e7;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
    cursor: pointer;
    padding-left: 2px;
    margin-left: 15px;
}

.player-content-statistics-filter-next:hover {
    background: #d0d0d0;
}

.player-content-statistics-filter-next img {
    width: 13px;
    height: 13px;
    object-fit: contain;
}

.player-content-statistics-filter-value {
    font-weight: 1000;
    font-size: 14px;
}


.player-content-widget-container-title {
    font-size: 14px;
    text-align: center;
    color: #555;
}

.player-content-widget-container-title span {
    font-weight: bold;
    color: #000;
}