.form-input {
    width: 100%;
    padding: 7px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.form-input-label {
    position: absolute;
    left: 25px;
    font-size: 14px;
    font-weight: 500;
    transition: transform 0.2s, font-size 0.2s;
}

.form-input-focused .form-input-label {
    transform: translateY(-25px);
    background: white;
    font-size: 12px;
}

.form-input input {
    width: 100%;
    height: 50px;
    border-radius: 25px;
    background: white;
    border: 1px solid #dedede;
    padding: 0 25px;
    font-size: 14px;
    outline: none;
    caret-color: #0277bd;
    transition: border-color 0.4s;
}

.form-input input:focus {
    border-color: #0277bd;
}

.form-input input[type=file] {
    padding-top: 14px;
}

.back-img {
    height: 150px;
    margin-left: 5px;
}

.form-input-error {
    position: absolute;
    background: #f44336;
    right: 20px;
    bottom: 0;
    font-size: 12px;
    font-weight: bold;
    padding: 3px 5px;
    border-radius: 5px;
    color: white;
}

.form-input-secure {
    position: absolute;
    z-index: 2;
    right: 5px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.form-input-secure img {
    width: 22px;
    height: 22px;
    object-fit: contain;
    opacity: 0.5;
}

.form-input-disabled {
    width: 100%;
    margin: 7px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: 100%;
    height: 50px;
    border-radius: 25px;
    background: #f0f0f0;
    border: 1px solid #dedede;
    padding: 0 25px;
    font-size: 14px;
    color: #444
}

.form-input-disabled-label {
    position: absolute;
    left: 25px;
    font-weight: 500;
    transform: translateY(-25px);
    background: transparent;
    font-size: 12px;
}


/* IMAGE INPUT STYLES */
.form-image-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    position: relative;
}

.form-image-box span {
    font-size: 13px;
    margin-bottom: 10px;
    color: #666;
    font-weight: 300;
}

.form-image {
    display: inline-block;
    position: relative;
    cursor: pointer;
}

.form-image-button {
    border: 1px dashed #666;
    width: 120px;
    height: 120px;
    justify-content: center;
    align-items: center;
    border-radius: 60px;
    background-color: #FAFAFA;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.form-image-button img {
    width: 40px;
    height: 40px;
    object-fit: contain;
    opacity: 0.8;
}

.form-image input[type=file] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    display: block;
    width: 0;
    height: 0;
}

.form-image-picture {
    width: 120px;
    height: 120px;
    border-radius: 60px;
    overflow: hidden;
    border: 1px solid #eaeaea;
    background: #FAFAFA;
}

.form-image-picture img {
    width: 120px;
    height: 120px;
    object-fit: cover;
}


.form-image-edit-button {
    position: absolute;
    top: 0;
    right: -10px;
    z-index: 1;
    width: 36px;
    height: 36px;
    border-radius: 13px;
    background: white;
    box-shadow: 0 0 10px #999;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.form-image-edit-button img {
    width: 16px;
    height: 16px;
    object-fit: contain;
}

.form-image-remove-button {
    position: absolute;
    bottom: -6px;
    margin-left: 84px;
    z-index: 2;
    width: 36px;
    height: 36px;
    border-radius: 13px;
    background: red;
    box-shadow: 0 0 10px #999;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.form-image-remove-button img {
    width: 13px;
    height: 13px;
    object-fit: contain;
}



.form-switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0 10px 10px;
}

.form-switch span {
    font-size: 14px;
    margin-right: 10px;
    font-weight: 500;
    color: #131414
}

.form-member-box {
    padding-bottom: 10px;
    border-bottom: 1px solid #e6e6e6;
}

.form-member-title {
    font-size: 14px;
    font-weight: bold;
    margin-left: 10px;
    margin-top: 20px;
}

.form-member {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.form-member-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 10px;
}

.form-member-name {
    font-size: 14px;
    font-weight: bold;
    color: #222
}

.form-member-role {
    font-size: 12px;
    color: #555
}

.form-status-title {
    font-size: 14px;
    font-weight: bold;
    margin-left: 10px;
    margin-top: 30px;
}



@media screen and (max-width: 768px) {
    .form-switch {
        margin: 10px 0;
    }

    .form-status-title,
    .form-member-title {
        margin-left: 0;
    }
}