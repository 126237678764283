
/* REFUND MODAL */
.refund--modal {
    outline: none;
    width: 100%;
    max-width: 400px;
    height: 300px;
    background: white;
    border-radius: 20px;
    position: relative;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    transform: translateY(-100%);
    transition: transform 200ms ease-in-out;
    overflow-x: hidden;
    overflow-y: auto;
}

.ReactModal__Overlay.refund--overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    padding: 30px 40px
}

.ReactModal__Overlay--after-open.refund--overlay {
    opacity: 1;
}

.ReactModal__Overlay--before-close.refund--overlay {
    opacity: 0;
}

.ReactModal__Overlay--after-open .refund--modal {
    transform: translateY(0);
}

.ReactModal__Overlay--before-close .refund--modal {
    transform: translateY(-100%);
}

.refund--modal-header {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d7d7d7;
    padding: 0 20px;
}

.refund--modal-header h2 {
    font-weight: 800;
    font-size: 18px;
}

.refund--modal-close {
    border: 1px solid #9F9F9F;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.refund--modal-close img {
    width: 10px;
    height: 10px;
    object-fit: contain;
}


.refund--modal-content {
    padding: 15px 20px;
}

.refund--modal-content input{
    width: 100%;
    height: 50px;
    border-radius: 10px;
    border: 1px solid #ccc;
    outline: none;
    padding: 20px;
}

.refund--modal-button {
    background: #2196f3;
    width: 100%;
    height: 50px;
    border-radius: 10px;
    margin-top: 10px;
    color: white;
    font-weight: bold;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: box-shadow 0.5s;
    cursor: pointer;
}

.refund--modal-button.disabled {
    cursor: default;
    box-shadow: none !important;
}

.refund--modal-button:hover {
    box-shadow: 0 0 20px #2196f3;
}

.refund--modal-information {
    margin-bottom: 20px;
}

.refund--modal-information-item {
    margin-bottom: 10px;
}

.refund--modal-information-text {
    font-size: 12px;
    color: #666;
}

.refund--modal-information-name {
    font-size: 14px;
    font-weight: bold;
    color: #333;
}