.cards--container {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.cards--container.additional {
    justify-content: flex-start;
    padding: 0px 20px 30px;
}

.cards--box {
    width: 32.5%;
    margin-right: 0.833%
}

.cards-head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
}

.cards--title {
    font-family: "Red Hat Display", sans-serif;
    font-weight: 1000;
    color: #b9bbce;
    font-size: 17px;
    text-transform: uppercase;
}

.cards--text {
    font-family: "Red Hat Display", sans-serif;
    font-weight: 1000;
    color: #607d8b;
    font-size: 17px;
    text-transform: uppercase;
    margin-left: 10px;
}

.cards--result {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
}

.cards--result-title {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
}

.cards--result-text {
    font-size: 12px;
    margin-left: 5px;
    color: #555
}



/* Card Styles */
.cards--list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
}

.cards--element {
    width: 50px;
    margin-right: 7px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
}

.cards--element.hidden > img {
    opacity: 0.3;
}

.cards--element img {
    width: 100%;
    object-fit: contain;
}

.cards--element-datetime {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cards--element-time {
    font-size: 11px;
    color: #777;
    font-weight: bold;
}

.cards--element-date {
    font-size: 9px;
    color: #999;
}

.cards--element-status {
    position: absolute;
    top: -5px;
    right: -5px;
    background: #f44336;
    box-shadow: 0 0 5px #f44336;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 8px;
    font-weight: bold;
    color: white;
    z-index: 2;
}

.cards--element-status.hidden img {
    width: 6px;
    height: 6px;
}

.cards--element-status img {
    width: 10px;
    height: 10px;
    object-fit: contain;
    filter: invert(1);
}

.cards--element-status.yellow {
    background: #ff9800;
    box-shadow: 0 0 5px #ff9800;
}

.cards--element-status.purple {
    background: #9c27b0;
    box-shadow: 0 0 5px #9c27b0;
}


.cards--empty {
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 40px;
    font-size: 14px;
    color: #b9bbce;
    text-transform: uppercase;
    font-weight: 900;
    border: 1px solid #b9bbce;
    border-radius: 20px;
}


@media screen and (max-width: 900px) {

    .cards--container {
        flex-direction: column;
    }

    .cards--box {
        width: 100%;
    }

}