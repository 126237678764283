.game--players {
    padding: 20px;
}

.game--players-head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
}

.game--players-head-title {
    font-family: "Red Hat Display", sans-serif;
    font-weight: 1000;
    color: #b9bbce;
    font-size: 20px;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.game--players-head-form {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    position: relative;
}

.game--players-head-form input {
    width: 240px;
    height: 40px;
    border-radius: 20px;
    padding-left: 15px;
    padding-right: 55px;
    border: 1px solid #ccc;
    outline: none;
    transition: border 0.3s;
}

.game--players-head-form input:focus {
    border: 1px solid #000
}

.game--players-head-form button {
    width: 40px;
    height: 40px;
    background: #03a9f4;
    border: none;
    outline: none;
    border-radius: 20px;
    position: absolute;
    right: 0;
    cursor: pointer;
    transition: box-shadow 0.4s;
}

.game--players-head-form button:hover {
    box-shadow: 0 0 20px #03a9f4;
}

.game--players-head-form button img {
    width: 16px;
    height: 16px;
    object-fit: contain;
    margin-right: 2px;
    margin-top: 2px;
}

.game--players-container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px
}

.game--players-element {
    width: 25%;
    padding: 10px;
}

.game--players-element-inner {
    padding: 10px;
    border-radius: 10px;background: #f1f5f8;
    border: 1px solid #dde9f1;
    cursor: pointer;
}

.game--players-element-user {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.game--players-element-text {
    margin-left: 10px;
}

.game--players-element-name {
    font-size: 14px;
    font-weight: bold;
}

.game--players-element-code {
    font-size: 13px;
    color: #666;
}

.game--players-element-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
}

.game--players-element-item img {
    width: 24px;
    height: 24px;
    object-fit: contain;
}

.game--players-element-item-text {
    padding-left: 10px;
}

.game--players-element-item-name {
    font-size: 12px;
    color: #333;
}

.game--players-element-item-value {
    font-size: 14px;
    font-weight: 1000;
}

.game--players-empty {
    padding: 100px;
    font-size: 20px;
    color: #999;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
}


/* Game player filter ID */

.game-player-filter{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}

.game-player-filter form {
    display: flex;
}

.game-player-filter input {
    height: 40px;
    width: 200px;
    border-radius: 20px;
    padding-left: 15px;
    padding-right: 50px;
    border: 1px solid #eaeaea;
    outline: none;
}

.game-player-filter button {
    padding-top: 2px;
    background: #03a9f4;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-left: -40px;
    border: none;
    outline: none;
    cursor: pointer;
}

.game-player-filter button img {
    width: 16px;
    height: 16px;
    object-fit: contain;
}

.inLine {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
}

.p-tester {
    color: #03a9f4;
    font-size: 12px;
    font-weight: 600;
    margin-left: 5px;
}


@media screen and (max-width: 900px) {
    .game--players-element {
        width: 50%;
    }
}

@media screen and (max-width: 600px) {
    .game--players-element {
        width: 100%;
    }
}




.player-game--modal {
    outline: none;
    width: 100%;
    height: 100%;
    background: white;
    border-radius: 20px;
    position: relative;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    transform: translateY(-100%);
    transition: transform 200ms ease-in-out;
    overflow-x: hidden;
    overflow-y: auto;
}

.ReactModal__Overlay.player-game--overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    padding: 30px 40px
}

.ReactModal__Overlay--after-open.player-game--overlay {
    opacity: 1;
}

.ReactModal__Overlay--before-close.player-game--overlay {
    opacity: 0;
}

.ReactModal__Overlay--after-open .player-game--modal {
    transform: translateY(0);
}

.ReactModal__Overlay--before-close .player-game--modal {
    transform: translateY(-100%);
}

.player-game--modal-header {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d7d7d7;
    padding: 0 20px;
}

.player-game--modal-header h2 {
    font-weight: 800;
    font-size: 18px;
}

.player-game--modal-close {
    border: 1px solid #9F9F9F;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.player-game--modal-close img {
    width: 10px;
    height: 10px;
    object-fit: contain;
}