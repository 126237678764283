/* Permission styles */
.permissions {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
}

.permission {
    width: 30%;
    margin-right: 3.33336%;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
}

.permission-type {
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 15px;
}

.permission-actions {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 5px;
}

.permission-action {
    font-size: 12px;
    margin-right: 5px;
    text-decoration: underline;
    cursor: pointer;
}

.permission-action:hover {
    text-decoration: none;
}

.permission-type span {
    font-weight: bold;
    text-transform: capitalize;
}

.permission-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 7px;
}

.permission-name {
    padding-left: 10px;
}

.permission-title {
    font-size: 14px;
}

.permission-text {
    font-size: 13px;
    color: #666
}


@media screen and (max-width: 768px) {
    .permissions {
        flex-direction: column;
    }

    .permission {
        width: 100%;
    }
}