.notes--container {
    position: fixed;
    right: -320px;
    z-index: 10;
    top: 60px;
    width: 360px;
    transition: right 0.3s;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.notes--container.open {
    right: 0;
}

.notes--container-toggle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ff9800;
    height: 130px;
    width: 40px;
    cursor: pointer;
    border-radius: 10px 0 0 10px;
    position: relative;
}

.notes--container-toggle img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    transform: rotate(-90deg);
    filter: invert(1);
    margin-top: 5px;
}

.notes--container-toggle-text {
    height: 80px;
    font-family: "Red Hat Display", sans-serif;
    text-transform: uppercase;
    writing-mode: vertical-rl;
    text-align: center;
    transform: rotate(180deg);
    font-size: 20px;
    font-weight: 1000;
    color: white;
}

.notes--container-toggle-count {
    position: absolute;
    left: -5px;
    top: -5px;
    background: #f44336;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 9px;
    color: white;
    font-weight: 1000;
}


.notes--container-messages {
    background: #fff;
    width: 320px;
    height: 300px;
    border-radius: 0 0 0 10px;
    box-shadow: 0 0 20px #999;
}

.notes--container-messages-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    border-bottom: 1px solid #ddd;
    padding: 0 10px;
}

.notes--container-messages-header-text {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 13px;
}

.notes--container-messages-header-close {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #bbb;
    cursor: pointer;
}

.notes--container-messages-header-close img {
    width: 8px;
    height: 8px;
    object-fit: contain;
}

.notes--container-messages-box {
    height: 200px;
    padding: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.notes--container-messages-box::-webkit-scrollbar {
    display: none;
}

.notes--container-messages-item {
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.notes--container-messages-user {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.notes--container-messages-user-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 10px;
}

.notes--container-messages-name {
    font-size: 14px;
    font-weight: 1000;
}

.notes--container-messages-time {
    font-size: 12px;
}

.notes--container-messages-text {
    font-size: 14px;
    color: #222;
    margin-top: 5px;
}

.notes--container-messages-loading {
    width: 320px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.notes--container-messages-loading span {
    font-size: 15px;
    color: #444;
}

.notes--container-messages-bottom {
    padding: 10px;
    position: relative;
    border-top: 1px solid #ddd;
}

.notes--container-messages-bottom input {
    width: 300px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid #ccc;
    outline: none;
    padding: 0 15px;
    transition: border 0.3s;
}

.notes--container-messages-bottom input:focus {
    border-color: #666;
}

.notes--container-messages-button {
    position: absolute;
    right: 10px;
    bottom: 10px;
    top: 10px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #03a9f4;
    padding-right: 2px;
    padding-top: 2px;
    cursor: pointer;
    transition: box-shadow 0.3s;
    font-size: 14px;
    color: white;
}

.notes--container-messages-button img {
    width: 15px;
    height: 15px;
    object-fit: contain;
    filter: invert(1);
}

.notes--container-messages-button:hover {
    box-shadow: 0 0 10px #03a9f4;
}