.header {
    position: absolute;
    right: 20px;
    top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.header-user {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    border-radius: 25px;
    background: #3b3b52;
    box-shadow: 0 0 5px #20203d;
    padding-left: 20px;
    cursor: pointer;
}

.header-user-text {
    margin-left: 10px;
    margin-right: 10px;
}

.header-user-role {
    font-size: 12px;
    font-weight: 300;
    color: white;
}

.header-user-name {
    font-weight: 800;
    color: white;
    font-size: 14px;
}

.header-user img {
    width: 30px;
    height: 30px;
    object-fit: contain;
}

.header-user img.mobile-image {
    display: none;
}


.header-more {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.header-more img {
    width: 18px;
    height: 18px;
    object-fit: contain;
}




/* HEADER NOTIFICATION STYLES */
.header-notification {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    border-radius: 25px;
    background: #3b3b52;
    box-shadow: 0 0 5px #20203d;
    margin-right: 10px;
    cursor: pointer;
    position: relative;
}

.header-notification img {
    width: 22px;
    height: 22px;
    object-fit: contain;
}

.header-notification-warning {
    background: #f44336;
    animation: notification-pulse 1s linear infinite;
}

/* RED ANIMATION */
@-webkit-keyframes notification-pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 #f44336;
        background: #f44336;
    }

    70% {
        -webkit-box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
        background: #3b3b52;
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        background: #3b3b52;
    }
}

@keyframes notification-pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 #f44336;
        box-shadow: 0 0 0 0 #f44336;
        background: #f44336;
    }

    70% {
        -moz-box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
        box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
        background: #3b3b52;
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        background: #f44336;
    }
}


.header-notification-dot {
    background: #f44336;
    width: 16px;
    height: 16px;
    position: absolute;
    right: 7px;
    bottom: 6px;
    border-radius: 8px;
    z-index: 1;
    font-size: 10px;
    color: white;
    font-weight: 800;
    display: flex;
    justify-content: center;
    align-items: center;
}





/* DROPDOWN MENU STYLES */
.header-dropdown-menu {
    position: absolute;
    z-index: 5;
    right: 17px;
    top: 70px;
    background: white;
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: 0 0 10px #555;
    width: 250px;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s linear 0.2s, opacity 0.2s linear;
}

.header-dropdown-menu.active {
    transition: visibility 0s, opacity 0.2s linear;
    visibility: visible;
    opacity: 1;
}

.header-dropdown-menu::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: -5px;
    right: 20px;
    width: 16px;
    height: 16px;
    background: white;
    border-radius: 3px;
    transform: rotate(45deg);
}

.header-dropdown-menu-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 40px;
    cursor: pointer;
}

.header-dropdown-menu-divider {
    border-bottom: 1px solid #dedede;
}

.header-dropdown-menu-item span {
    font-size: 14px;
    font-weight: 300;
    color: #141414;
    padding-left: 6px;
}

.header-dropdown-menu-item img {
    width: 18px;
    height: 18px;
    object-fit: contain;
    opacity: 0.8;
}




@media screen and (max-width: 900px) {

    .header-notification {
        width: 40px;
        background: transparent;
        box-shadow: none;
        margin-right: 0;
    }

    .header-user {
        width: 40px;
        padding: 0;
        background: transparent;
        box-shadow: none;
    }

    .header-user-text,
    .header-more {
        display: none;
    }

    .header-user img {
        display: none;
        width: 22px;
        height: 22px;
    }

    .header-user img.mobile-image {
        display: block;
    }

    .header-dropdown-menu {
        right: 12px;
    }

}