.o-filter {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    /* overflow: hidden; */
    margin-left: 20px;
    z-index: 4;
}

.o-filter-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 4px;
    height: 40px;
    border-radius: 25px;
    background: #4e4d65;
    box-shadow: 0 0 5px #20203d;
    transition: all 0.4s;
    cursor: pointer;
}

.o-filter-btn:hover {
    background: #666483;
    transform: scale(1.02);
}

.o-filter-btn:active {
    opacity: 0.3;
}

.o-filter-btn-text {
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 15px;
    font-weight: 600;


}

.o-filter-btn-text span {
    background-color: #2196f3;
    font-weight: 800;
    color: white;
    font-size: 15px;
    margin-left: 8px;
    padding: 2px 8px;
    border-radius: 14px;
}

.o-filter-more {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.o-filter-more img {
    width: 18px;
    height: 18px;
    object-fit: contain;
}

/* DROPDOWN MENU STYLES */
.o-filter-dropdown-menu {
    position: absolute;
    z-index: 5;
    right: 17px;
    top: 60px;
    background: white;
    padding: 20px 10px;
    border-radius: 10px;
    box-shadow: 0 0 10px #555;
    width: 250px;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s linear 0.2s, opacity 0.2s linear;
}

.o-filter-dropdown-menu.active {
    transition: visibility 0s, opacity 0.2s linear;
    visibility: visible;
    opacity: 1;
}

.o-filter-dropdown-menu::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: -7px;
    right: 50px;
    width: 14px;
    height: 14px;
    background: white;
    border-radius: 2px;
    transform: rotate(45deg);
}

.o-filter-dropdown-menu-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 40px;
    cursor: pointer;
    border: 1.3px solid #e2e2e2;
    margin-bottom: 10px;
    border-radius: 8px;
    position: relative;
}

.o-filter-dropdown-menu-item:hover {
    background: #eaeaea;
}

.o-filter-dropdown-menu-item.last {
    margin-bottom: 0px;
}

.o-filter-dropdown-menu-item span {
    font-size: 16px;
    font-weight: 600;
    color: #141414;
    padding-left: 10px;
}

.o-filter-check-block {
    width: 28px;
    height: 28px;
    border: 1px solid #2196f3;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 5px;
    border-radius: 5px;
    transition: all 0.4s;
}

.o-filter-check-block:hover {
    background-color: #c7e2f7;
    transform: scale(1.05);
}

.o-filter-check-block.checked {
    background-color: #2196f3;
}

.o-filter-check-block p {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: white;
}


.o-filter-bottom {
    width: 100%;
    margin-top: 15px;
}

.o-filter-button {
    width: 100%;
    height: 40px;
    background-color: #2196f3;
    border-radius: 10px;
    color: white;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
}

.o-filter-button.active {
    opacity: 1;
    cursor: pointer;
}

.o-filter-button.active:hover {
    background-color:rgb(72, 171, 252)
}



@media screen and (max-width: 900px) {

    .o-filter-btn {
        width: 40px;
        padding: 0;
        background: transparent;
        box-shadow: none;
    }

    .o-filter-btn-text {
        display: none;
    }

    .o-filter-dropdown-menu {
        right: 12px;
    }

}