.reload {
    background: #292944;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.reload.loading {
    cursor: default;
    background: #999;
}

.reload.loading span {
    font-size: 14px;
    color: white
}

.reload img {
    width: 16px;
    height: 16px;
    object-fit: contain;
    filter: invert(1);
}