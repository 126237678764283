.statistics-information-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 100px;
}

.statistics-information-container-error {
    position: absolute;
    width: 100%;
    margin: 10px 20px;
    padding: 5px;
    width: calc(100% - 40px);
    text-align: center;
    background: rgba(255, 152, 0, 0.3);
    top: 0;
    color: #754600;
    border: 1px solid #ff9800;
    border-radius: 5px;
    font-weight: bold;
    font-size: 14px;
    width: 100%;
    max-width: 500px;
    left: calc(50% - 250px);
}


.statistics-information-widget {
    background: #f1f5f8;
    border: 1px solid #dde9f1;
    padding: 20px;
    margin: 20px 0 20px 20px;
    border-radius: 10px;
    width: 300px;
}

.statistics-information-widget-currency {
    font-size: 30px;
    font-family: "Red Hat Display", sans-serif;
    color: #b9bbce;
    margin-top: 20px;
    font-weight: 1000;
}

.statistics-information-widget-user {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 0.5px solid #c2d0d9;
}

.statistics-information-widget-user img {
    width: 30px;
    height: 30px;
    object-fit: contain;
}

.statistics-information-widget-user-text {
    padding-left: 10px;
}

.statistics-information-widget-user-name {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: #222;
}

.statistics-information-widget-user-code {
    font-size: 12px;
    color: #666;
}

.statistics-information-widget-element {
    min-height: 40px;
    padding: 10px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.statistics-information-widget-element img {
    width: 18px;
    height: 18px;
    object-fit: contain;
}

.statistics-information-widget-element-text {
    padding-left: 10px;
}

.statistics-information-widget-element-name {
    font-size: 12px;
    color: #666
}

.statistics-information-widget-element-value {
    font-weight: bold;
    font-size: 15px;
    color: #222;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.statistics-information-widget-element-value img {
    width: 14px;
    height: 14px;
    object-fit: contain;
    margin-right: 4px;
}

.statistics-information-widget-element-value.green {
    color: #8bc34a
}

.statistics-information-widget-element-value.red {
    color: #f44336
}




/* COMBINATIONS */
.statistics-information-combinations {
    width: 300px;
    padding: 20px;
    border-left: 1px solid #f3f3f3;
    margin-top: 20px;
}

.statistics-information-combinations h3 {
    font-size: 17px;
    margin-bottom: 6px;
}

.statistics-information-combination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 0;
}

.statistics-information-combination-name {
    font-size: 13px;
    font-weight: 500;
    color: #555
}

.statistics-information-combination-value {
    font-size: 13px;
    color: #555
}





/* CHART */
.statistics-information-chart {
    width: calc(100% - 600px);
}




.chart-content-statistics-head {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
}

.chart-content-statistics-title {
    font-size: 18px;
    font-weight: bold;
    color: #141414;
}

.chart-content-statistics-head-filter {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.chart-content-statistics-head-filter-item {
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #c1c1c1;
    border-radius: 5px;
    margin-left: 10px;
    height: 30px;
    font-size: 13px;
    color: #222;
    cursor: pointer;
}

.chart-content-statistics-head-filter-item.active {
    background: #03a9f4;
    border-color: #03a9f4;
    color: white;
    font-weight: bold;
}





.chart-content-statistics-filter {
    padding: 10px 0;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chart-content-statistics-filter-prev {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background: #e7e7e7;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
    cursor: pointer;
    padding-right: 2px;
    margin-right: 15px;
}

.chart-content-statistics-filter-prev:hover {
    background: #d0d0d0;
}

.chart-content-statistics-filter-prev img {
    width: 13px;
    height: 13px;
    object-fit: contain;
}

.chart-content-statistics-filter-next {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background: #e7e7e7;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
    cursor: pointer;
    padding-left: 2px;
    margin-left: 15px;
}

.chart-content-statistics-filter-next:hover {
    background: #d0d0d0;
}

.chart-content-statistics-filter-next img {
    width: 13px;
    height: 13px;
    object-fit: contain;
}

.chart-content-statistics-filter-value {
    font-weight: 1000;
    font-size: 14px;
}



.chart-content-statistics-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.chart-content-statistics-container-inner {
    height: 300px;
    position: relative;
    width: 100%;
    margin-bottom: 30px;
}

.chart-content-statistics-container>span {
    position: absolute;
    font-size: 30px;
    z-index: 1;
    color: #999;
    margin-bottom: 20px;
}



/* INFORMATION */
.chart-content-statistics-value {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    padding-bottom: 10px;
}

.chart-content-statistics-information {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 40px);
    padding: 0 20px;
    padding: 10px;
    margin: 0 20px;
    border-radius: 10px;
    background: #333;
    border: 1px solid #222
}

.chart-content-statistics-information-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
}

.chart-content-statistics-information-item:last-child {
    border-right: none;
}

.chart-content-statistics-information-container {
    width: 100%;
}

.chart-content-statistics-information-item img {
    width: 18px;
    height: 18px;
    object-fit: contain;
    filter: invert(1);
}

.chart-content-statistics-information-box {
    padding-left: 10px;
}

.chart-content-statistics-information-text {
    font-size: 12px;
    color: #fff;
}

.chart-content-statistics-information-value {
    font-weight: bold;
    font-size: 15px;
    color: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.chart-content-statistics-information-value img {
    width: 14px;
    height: 14px;
    object-fit: contain;
    margin-right: 4px;
    margin-top: 0px;
}


.chart-content-statistics-information-container-currency {
    font-size: 16px;
    font-family: "Red Hat Display", sans-serif;
    color: #b9bbce;
    font-weight: 1000;
    text-align: center;
    margin: 20px 20px 0 20px;
    text-transform: uppercase;
}



.similar-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    display: inline-block;
}

.similar-text img {
    width: 12px;
    height: 12px;
    object-fit: contain;
    margin-top: 5px;
}





@media screen and (max-width: 1200px) {

    .chart-content-statistics-information {
        flex-wrap: wrap;
    }

    .chart-content-statistics-information-item {
        width: 50%;
        justify-content: flex-start;
        margin: 10px 0;
    }
}


@media screen and (max-width: 900px) {
    .statistics-information-container {
        flex-direction: column;
    }

    .statistics-information-widget {
        width: calc(100% - 40px);
    }

    .statistics-information-combinations {
        width: 100%;
    }

    .statistics-information-chart {
        width: 100%;
    }

    .statistics-information-container {
        position: relative;
        padding-top: 50px;
    }

    .statistics-information-container-error {
        max-width: calc(100% - 40px);
        left: 0;
    }
}