/* PAGE STYLES */
.page {
    min-height: 100%;
    padding: 20px;
}

/* PAGE HEADER STYLES */
.page-header {
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
}

.page-header h1 {
    font-size: 28px;
    color: white;
    font-weight: 1000;
}

.page-header .button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    background: #03a9f4;
    height: 30px;
    padding: 0 15px;
    border-radius: 18px;
    cursor: pointer;
    margin-top: 5px;
}

.page-header .button img {
    width: 14px;
    height: 14px;
    object-fit: contain;
}

.page-header .button span {
    font-size: 14px;
    color: white;
    font-weight: 1000;
    padding-left: 6px;
}








/* TABLE STYLES */
.page-table {
    background: white;
    padding: 20px;
    border-radius: 20px;
    min-height: calc(100vh - 110px);
}

.page-table-header {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #dedede;
    height: 40px;
}

.page-table-body {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #dedede;
    min-height: 45px;
    padding: 5px 0;
}

.page-table-header .page-table-column {
    font-weight: 1000;
}

.page-table-column {
    font-size: 14px;
    font-weight: 400;
    padding-right: 5px;
    color: #222;
}

.page-table-column.bold {
    font-weight: 1000;
    text-transform: capitalize;
}

.page-table-column.card img {
    width: 40px;
    height: 40px;
    object-fit: contain;
}

.page-table-column.number {
    width: 5%;
    font-weight: 800;
}

.page-json {
    display: block;
    margin-top: 5px;
}

.page-json-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.page-json-bold {
    margin-right: 5px;
    font-weight: bold;
    font-size: 12px;
}

.page-json-text {
    font-size: 12px;
}

.page-table-time {
    font-size: 14px;
    font-weight: 800;
}

.page-table-date {
    font-size: 12px;
    font-weight: 200;
}

.notification-button {
    background: #03a9f4;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 800;
    color: white;
    border-radius: 5px;
    width: 100px;
}





/* PAGE TABLE DROPDOWN MENU STYLES */
.page-table-column.more {
    width: 5%;
    position: relative;
}

.more-dropdown-menu-icon {
    width: 50px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.more-dropdown-menu-icon img {
    width: 26px;
    height: 26px;
    object-fit: contain;
}

.more-dropdown-menu {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    z-index: 1;
    right: 0;
    background: white;
    padding: 10px 15px;
    border: 1px solid #FAFAFA;
    box-shadow: 0 0 10px #BBB;
    min-width: 200px;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s linear 0.2s, opacity 0.2s linear;
    border-radius: 15px;
}

.more-dropdown-menu::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: -5px;
    right: 19px;
    width: 16px;
    height: 16px;
    background: white;
    border-radius: 3px;
    transform: rotate(45deg);
}

.page-table-column.more:hover .more-dropdown-menu {
    transition: visibility 0s, opacity 0.2s linear;
    visibility: visible;
    opacity: 1;
}



.more-dropdown-menu-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 40px;
    padding: 5px 0;
    cursor: pointer;
    width: 100%;
    border-bottom: 1px solid #dedede;
}

.more-dropdown-menu-item img {
    width: 16px;
    height: 16px;
    object-fit: contain;
}

.more-dropdown-menu-item span {
    margin-left: 5px;
    color: #232323
}

.more-dropdown-menu-item.red {
    border-bottom: none;
}

.more-dropdown-menu-item.red span {
    color: #f44336;
    font-weight: 600;
}


.page-table-column.button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.page-table-column-button {
    background: #03a9f4;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 3px;
    width: 36px;
    height: 36px;
    border-radius: 10px;
    cursor: pointer;
    transition: opacity 0.3s;
    opacity: 1;
}

.page-table-column-button:hover {
    opacity: 0.8;
}

.page-table-column-button.red {
    background: #f44336;
}

.page-table-column-button.red img {
    width: 10px;
    height: 10px;
    object-fit: contain;
}

.page-table-column-button img {
    width: 12px;
    height: 12px;
    object-fit: contain;
}



/* TAG STYLES */
.tag {
    background: #03a9f4;
    display: inline-block;
    padding: 3px 6px;
    color: white;
    font-size: 12px;
    font-weight: 1000;
    border-radius: 6px;
}

.tag.pink {
    background: #e91e63;
}

.tag.purple {
    background: #3f51b5;
}

.tag.green {
    background: #8bc34a;
}

.tag.red {
    background: #f44336;
}

.tag.bluegray {
    background: #607d8b;
}

.tag.orange {
    background: #ff8a1f;
}



/* FORM STYLES */
.page-form {
    background: white;
    padding: 20px;
    border-radius: 20px;
    min-height: calc(100vh - 110px);
}

.form {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.form-box {
    width: 48%;
    margin-right: 2%;
}

.extended-access {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 5px;
}

.extended-access p {
    font-size: 15px;
    font-weight: 700;
}

.form-box select {
    width: 100%;
    margin: 7px 0;
    height: 50px;
    border-radius: 25px;
    background: white;
    border: 1px solid #dedede;
    padding: 0 25px;
    font-size: 14px;
    outline: none;
    caret-color: #0277bd;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-indent: 1px;
    text-overflow: '';
}

.form-box select::-ms-expand {
    display: none;
}

.form-generate-password {
    text-align: right;
    cursor: pointer;
    font-weight: bold;
    font-size: 12px;
    color: #03a9f4;
    padding-right: 15px;
}

.form-bottom {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.form-bottom-full {
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-top: 40px;
}

.form-bottom .button {
    background: #03a9f4;
    width: 48%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    border-radius: 25px;
    color: white;
    font-weight: 800;
    cursor: pointer;
    transition: transform 0.3s;
}

.form-bottom .button:hover {
    transform: scale(1.05);
}

.form-space {
    height: 20px;
}



/* GAME FORM STYLES */
.form-game-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 50px;
    padding: 10px 0;
    border-bottom: 1px solid #dedede;
}

.form-game-item-text {
    width: calc(100% - 100px);
}

.form-game-item-name {
    font-weight: 800;
    font-size: 14px;
    color: #222
}

.form-game-item-slug {
    font-size: 12px;
    color: #888
}

@media screen and (max-width: 900px) {


    /* MOBILE PAGE HEADER */
    .page-header h1 {
        font-size: 18px;
        max-width: calc(100% - 100px);
    }

    /* MOBILE HEADER BUTTON */
    .page-header .button {
        margin-left: 10px;
        height: 30px;
        width: 30px;
        padding: 0;
        border-radius: 15px;
    }

    .page-header .button span {
        display: none;
    }

    /* MOBILE TABLE STYLES */
    .page-table-header,
    .page-table-column.number {
        display: none;
    }

    .page-table-body {
        flex-direction: column;
    }

    .page-table-body.dealers,
    .page-table-body.currencies,
    .page-table-body.cards {
        flex-direction: row;
    }

    .page-table-body.dealers .page-table-column,
    .page-table-body.currencies .page-table-column,
    .page-table-body.cards .page-table-column {
        padding-left: 5px;
    }

    .page-table-body .page-table-column {
        width: 100% !important;
    }


    /* MOBILE FORM STYLES */
    .form {
        flex-direction: column;
    }

    .page-form {
        min-height: 100%;
    }

    .form-box {
        width: 100%;
        margin-right: 0;
    }

    .form-bottom {
        width: 100%;
    }
}


.stream-button {
    background: #03a9f4;
    height: 35px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 800;
    font-size: 14px;
    cursor: pointer;
    margin: 10px 0;
    transition: box-shadow 0.4s;
}

.stream-button:hover {
    box-shadow: 0 0 20px #03a9f4;
}