/* Container Styles */
.j-test-container {
    height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


/* Card Styles */
.j-cards-container {
    height: 100vh;
    width: 60%;
    padding: 20px;
}

.j-cards-title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}

.j-cards-title img {
    width: 50px;
    height: 50px;
}

.j-cards-title h1 {
    font-size: 40px;
    margin-left: 10px;
    text-transform: uppercase;
}

.j-cards-head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.j-cards-head h2 {
    font-size: 36px;
    font-weight: 1000;
    color: #121212;
}

.j-cards-clear {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f44336;
    border-radius: 10px;
    width: 150px;
    height: 35px;
    margin-left: 15px;
    font-size: 13px;
    color: white;
    font-weight: 700;
    cursor: pointer;
    margin-top: 5px;
    transition: box-shadow 0.3s;
}

.j-cards-clear:hover {
    box-shadow: 0 0 10px #f44336;
}

.j-cards-clear img {
    width: 14px;
    height: 14px;
    object-fit: contain;
    filter: invert(1);
    margin-right: 5px;
}

.j-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.j-card {
    width: calc(100% / 13);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
}

.j-card.disabled {
    cursor: default;
}

.j-card.active:hover {
    transform: scale(1.1);
    transition: transform 0.2s;
}

.j-card img {
    width: 90%;
    height: 100%;
    object-fit: contain;
}

.j-card-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.j-card-button {
    width: 150px;
    height: 40px;
    border-radius: 10px;
    background: #8bc34a;
    margin: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    transition: box-shadow 0.5s, opacity 0.5s;
}

.j-card-button:hover {
    box-shadow: 0 0 20px #8bc34a;
}

.j-card-button.pink {
    background: #e91e63;
}

.j-card-button.pink:hover {
    box-shadow: 0 0 20px #e91e63;
}

.j-card-button.purple {
    background: #9c27b0;
}

.j-card-button.purple:hover {
    box-shadow: 0 0 20px #9c27b0;
}




/* Result Styles */
.j-result-container {
    height: 100vh;
    width: 40%;
}

/* BOX Styles */
.j-box {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    background: #222;
    margin: 20px;
    border-radius: 10px;
    box-shadow: 0 0 20px #666;
}

.j-box-text {
    font-size: 12px;
    color: #fff;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: 700;
}

.j-box-cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 20px;
    position: relative;
}

.j-box-spaces {
    display: flex;
    justify-content: center;
    align-items: center;
}

.j-box-space {
    width: 33.369px;
    height: 50px;
    background: #333;
    margin: 3px;
    border-radius: 5px;
    border: 1px solid #222;
}

.j-box-deal-cards {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 0;
}

.j-box-deal-card {
    width: 35.369px;
    height: 52px;
    margin: 3px;
    position: relative;
    display: flex;
    border-radius: 4px;
}

.j-box-deal-card.joker {
    border: 1px solid yellow;
}

.j-box-deal-card .j-card-text {
    position: absolute;
    font-size: 11px;
    color: yellow;
    bottom: -17.5px;
    font-weight: 600;

}

.j-box-deal-card img {
    width: 33.369px;
    height: 50px;
    object-fit: contain;
}



/* Result Styles */
.j-results {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 20px;
}

.j-result {
    width: 50%;
    margin-top: 5px;
}

.j-result-title {
    font-size: 14px;
    font-weight: 700;
}

.j-result-text {
    font-size: 14px;
    color: #333;
    margin-top: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.214);
}

.j-result-text .bold-text {
    font-weight: 600;
    margin-left: 10px;
}

.j-result-text .bold-text.combination {
    background-color: #8bc34a;
    padding: 2px 3px;
    border-radius: 2px;
}

.j-result-text p.green {
    font-weight: bold;
    padding-left: 5px;
    color: green;
    text-transform: uppercase;
}

.j-result-text p.red {
    font-weight: bold;
    padding-left: 5px;
    color: red;
    text-transform: uppercase;
}


/* Forms */
.j-result-form {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    background: #222;
    margin: 10px 20px;
    border-radius: 10px;
    box-shadow: 0 0 20px #666;
}

.j-result-input-text {
    font-size: 12px;
    color: white;
    font-weight: bold;
    margin-bottom: 10px;
}

.j-result-input {
    width: 33%;
    padding: 10px;
}

.j-result-input input {
    width: 100%;
    height: 30px;
    border-radius: 10px;
    border: 1px solid black;
    outline: none;
    padding: 0 15px;
    background: #444;
    color: white;
}

