.bonus--page {
    min-height: 100%;
    padding: 20px;
}

/* PAGE HEADER STYLES */
.bonus--page-header {
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
}

.bonus--page-header h1 {
    font-size: 28px;
    color: white;
    font-weight: 1000;
}

/* Filters */
.bonus--page-filters {
    display: flex;
    flex-direction: row;
}

/* Search */
.bonus--page-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    margin-right: 10px;
    margin-top: 4px;
    position: relative;
}

.bonus--page-search input {
    height: 36px;
    width: 240px;
    border-radius: 18px;
    padding-left: 38px;
    padding-right: 10px;
    border: 1px solid #eaeaea;
    outline: none;
}

.bonus--page-search img {
    position: absolute;
    left: 15px;
    width: 18px;
    height: 18px;
    object-fit: contain;
    opacity: 0.7;
}

.bonus--page-filters-box {
    display: flex;
    flex-direction: row;
}

/* Filter */
.bonus--page-filter {
    margin: 2px 10px 0 10px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-top: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #03a9f4;
    cursor: pointer;
    transition: box-shadow 0.4s;
    padding-top: 3px;
    position: relative;
}

.bonus--page-filter:hover {
    box-shadow: 0 0 20px #03a9f4;
}

.bonus--page-filter img {
    width: 18px;
    height: 18px;
}

.bonus--page-filter-count {
    position: absolute;
    bottom: -2px;
    right: -2px;
    background: #f44336;
    width: 18px;
    height: 18px;
    color: white;
    font-family: "Red Hat Display", sans-serif;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9px;
    font-weight: 1000;
}

/* Excel */
.bonus--page-excel {
    height: 40px;
    width: 70px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: "Red Hat Display", sans-serif;
    font-size: 13px;
    font-weight: 1000;
    background: #ff9800;
    margin: 2px 10px 0 10px;
    cursor: pointer;
    transition: box-shadow 0.4s;
}

.bonus--page-excel:hover {
    box-shadow: 0 0 20px #ff9800;
}


/* Settings */
.bonus--page-settings {
    background: #e91e63;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    border-radius: 20px;
    margin: 2px 10px 0 10px;
    cursor: pointer;
    transition: box-shadow 0.4s;
}

.bonus--page-settings:hover {
    box-shadow: 0 0 20px #e91e63;
}

.bonus--page-settings-text {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: white;
    font-size: 13px;
    font-family: 'Red Hat Display', sans-serif;
    font-weight: 1000;
}

.bonus--page-settings-text span {
    font-size: 11px;
    font-weight: normal;
    font-family: 'Google Sans';
}

.bonus--page-settings img {
    width: 18px;
    height: 18px;
    object-fit: contain;
    margin-right: 6px;
}




@media screen and (max-width: 768px) {

    .bonus--page-header {
        height: 100px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .bonus--page-filters {
        width: 100%;
        flex-direction: column;
    }

    .bonus--page-search {
        margin-top: 20px;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
    }

    .bonus--page-search input {
        width: 100%;
    }

    .bonus--page-filters-box {
        margin-top: 20px;
        justify-content: flex-end;
        align-items: center;
        position: relative;
    }

    .bonus--page-settings {
        margin: 0;
    }

    .bonus--page-filter {
        position: absolute;
        left: 0;
        margin: 0;
        z-index: 1;
    }
}


.bonus--content-layout-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 200px);
    color: #888
}

/* Container Styles */
.bonus--container {
    background: white;
    min-height: calc(100vh - 100px);
    margin-bottom: 150px;
    margin-top: 20px;
    border-radius: 20px;
    padding: 20px 10px;
}

.bonus-content-head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #f2f2f2;
    width: 100%;
    padding: 5px 10px;
}

.bonus-content-head-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: calc(70% / 4);
    padding: 5px 10px;
    font-size: 12px;
    font-weight: bold;
    color: #333
}

.bonus-content-head-text.operator {
    width: 10%;
}

.bonus-content-head-text.date {
    width: 10%;
}

.bonus-content-head-number {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 5%;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: bold;
    color: #333
}

.bonus-content-head-more {
    width: 5%;
}

.bonus-content-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #f2f2f2;
    padding: 5px 10px;
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
}

.bonus-content-row:hover {
    background: rgba(0, 0, 0, 0.05);
}

.bonus-content-text {
    padding: 5px;
    width: calc(70% / 4);
    padding: 5px 10px;
    font-size: 13px;
    font-weight: bold;
    color: #333;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.bonus-content-text.operator {
    width: 10%;
}

.bonus-content-text.date {
    width: 10%;
}

.bonus-content-text.table span {
    font-size: 11px;
    font-weight: normal;
    color: "#999"
}

.bonus-content-number {
    padding: 5px;
    width: 5%;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: bold;
    color: #333;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.bonus-content-user {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.bonus-content-user-box {
    padding-left: 8px;
}

.bonus-content-user-player {
    font-size: 11px;
    font-weight: normal;
    color: "#999"
}

.bonus-content-text.bonus span {
    font-size: 12px;
    font-weight: bold;
    color: #3f51b5
}

.bonus-content-text.win span {
    font-size: 11px;
    font-weight: bold;
    color: #8bc34a
}

.bonus-content-text.win span.blue {
    color: #3f51b5
}

.bonus-content-text.date span {
    font-size: 11px;
    font-weight: normal;
    color: "#999"
}

.bonus-content-more {
    width: 5%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 10px;
}

.bonus-content-arrow img {
    width: 12px;
    height: 12px;
    object-fit: contain;
    opacity: 0.6;
}


@media screen and (max-width: 768px) {

    .bonus--container {
        background: transparent;
        margin-top: 80px;
        padding: 0;
    }

    .bonus-content-head {
        display: none;
    }

    .bonus--container .pagination-box {
        background-color: white;
        padding: 10px;
        border-radius: 20px;
    }

    .bonus-content-row {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;
        border-bottom: none;
        padding: 10px 0;
        border-radius: 0;
        background: white;
        margin-bottom: 30px;
        border-radius: 20px;
        padding: 15px 10px;
    }

    .bonus-content-row:hover {
        background: #eaeaea;
    }

    .bonus-content-more,
    .bonus-content-number {
        display: none;
    }

    .bonus-content-text {
        width: 100%;
    }

    .bonus-content-text.operator {
        width: calc(100% - 80px);
    }

    .bonus-content-text.operator::before {
        content: "Оператор: ";
        font-size: 12px;
        font-weight: normal;
        color: "#666"
    }

    .bonus-content-text.table::before {
        content: "Игра: ";
        font-size: 12px;
        font-weight: normal;
        color: "#666"
    }

    .bonus-content-text.bonus::before {
        content: "Ставка: ";
        font-size: 12px;
        font-weight: normal;
        color: "#666";
        margin-top: 10px;
    }

    .bonus-content-text.win::before {
        content: "Выигрыш: ";
        font-size: 12px;
        font-weight: normal;
        color: "#666";
        margin-top: 10px;
    }

    .bonus-content-text.date {
        width: 90px;
        position: absolute;
        right: 0;
    }

    .bonus-content-user {
        background: rgba(0, 0, 0, 0.05);
        width: 100%;
        justify-content: flex-start;
        padding: 10px;
        border-radius: 10px;
    }

}







/* BONUS MODAL */
.bonus--modal {
    outline: none;
    width: 100%;
    max-width: 600px;
    height: 100%;
    background: white;
    border-radius: 20px;
    position: relative;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    transform: translateY(-100%);
    transition: transform 200ms ease-in-out;
    overflow-x: hidden;
    overflow-y: auto;
}

.ReactModal__Overlay.bonus--overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    padding: 30px 40px
}

.ReactModal__Overlay--after-open.bonus--overlay {
    opacity: 1;
}

.ReactModal__Overlay--before-close.bonus--overlay {
    opacity: 0;
}

.ReactModal__Overlay--after-open .bonus--modal {
    transform: translateY(0);
}

.ReactModal__Overlay--before-close .bonus--modal {
    transform: translateY(-100%);
}

.bonus--modal-header {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d7d7d7;
    padding: 0 20px;
}

.bonus--modal-header h2 {
    font-weight: 800;
    font-size: 18px;
}

.bonus--modal-close {
    border: 1px solid #9F9F9F;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.bonus--modal-close img {
    width: 10px;
    height: 10px;
    object-fit: contain;
}


.bonus--modal-container {
    width: 100%;
    padding: 10px 20px;
}

.bonus--modal-box {
    display: flex;
}

.bonus--modal-element {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 20px;
    width: 50%;
    border-bottom: 1px solid #ddd;
    height: 70px;
}

.bonus--modal-element.big {
    height: 100px;
}

.bonus--modal-element:first-child {
    border-right: 1px solid #ddd;
}

.bonus--modal-element>img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    opacity: 0.5;
}

.bonus--modal-element-inner {
    padding-left: 10px;
}

.bonus--modal-element-name {
    font-size: 12px;
    color: #666
}

.bonus--modal-element-value {
    font-size: 14px;
    font-weight: bold;
    color: #333
}

.bonus--modal-element-value.green {
    color: #8bc34a
}

.bonus--modal-element-value.red {
    color: #f44336
}

.bonus--modal-element-inner {
    font-size: 12px;
    color: #666
}

.bonus--modal-element-value.small {
    font-size: 12px;
}






@media screen and (max-width: 768px) {


    .ReactModal__Overlay.bonus--overlay {
        padding: 20px;
    }

    .bonus--modal-box {
        flex-direction: column;
    }

    .bonus--modal-element {
        width: 100%;
        padding: 0;
    }

    .bonus--modal-element:first-child {
        border-right: none
    }
}








/* JACKPOT MODAL STYLES */
.bonus-jackpot--modal {
    outline: none;
    width: 100%;
    max-width: 800px;
    height: 100%;
    background: white;
    border-radius: 20px;
    position: relative;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    transform: translateY(-100%);
    transition: transform 200ms ease-in-out;
    overflow-x: hidden;
    overflow-y: auto;
}

.bonus-jackpot--modal.bonus-jackpot--update {
    max-width: 500px;
    max-height: 370px;
}

.bonus-jackpot--modal.bonus-jackpot--connect {
    max-width: 500px;
}

.ReactModal__Overlay.bonus-jackpot--overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    padding: 30px 40px
}

.ReactModal__Overlay--after-open.bonus-jackpot--overlay {
    opacity: 1;
}

.ReactModal__Overlay--before-close.bonus-jackpot--overlay {
    opacity: 0;
}

.ReactModal__Overlay--after-open .bonus-jackpot--modal {
    transform: translateY(0);
}

.ReactModal__Overlay--before-close .bonus-jackpot--modal {
    transform: translateY(-100%);
}

.bonus-jackpot--modal-header {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d7d7d7;
    padding: 0 20px;
}

.bonus-jackpot--modal-header h2 {
    font-weight: 800;
    font-size: 18px;
}

.bonus-jackpot--modal-close {
    border: 1px solid #9F9F9F;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.bonus-jackpot--modal-close img {
    width: 10px;
    height: 10px;
    object-fit: contain;
}

/* Empty Styles */
.bonus-jackpot--empty {
    height: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bonus-jackpot--empty img {
    width: 100px;
    height: 100px;
    object-fit: contain;
    opacity: 0.5;
}

.bonus-jackpot--empty h2 {
    font-size: 20px;
    margin-top: 15px;
    color: #333
}

.bonus-jackpot--empty h2 span {
    font-family: "Red Hat Display", 'Nunito Sans';
    font-weight: 1000;
}

.bonus-jackpot--empty p {
    color: #666;
    font-size: 14px;
    margin-top: 5px;
}

.bonus-jackpot--empty-button {
    background: #03a9f4;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    border-radius: 20px;
    color: white;
    font-weight: 800;
    cursor: pointer;
    transition: box-shadow 0.4s;
    width: 160px;
    font-size: 14px;
}

.bonus-jackpot--empty-button:hover {
    box-shadow: 0 0 20px #03a9f4;
}



/* Container Styles */
.bonus-jackpot--container {
    padding: 20px 0 20px 20px;
    display: flex;
    flex-wrap: wrap;
}

.bonus-jackpot--operator {
    width: calc(50% - 15px);
    margin-right: 10px;
    padding: 20px 5px;
    border-radius: 10px;
    transition: box-shadow 0.3s, transform 0.3s;
    margin-bottom: 10px;
    border: 2px solid #5437a1;
    background-image: linear-gradient(to left bottom, #371f81, #311c73, #2c1966, #261659, #21134c);
    cursor: pointer;
    position: relative;
}

.bonus-jackpot--operator:hover {
    box-shadow: 0 0 20px #666;
    transform: scale(1.005);
}

.bonus-jackpot--operator-head {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.bonus-jackpot--operator-name {
    font-size: 24px;
    font-weight: 1000;
    font-family: "Red Hat Display", 'Nunito Sans';
    color: #fff;
    margin-top: 5px;
}

.bonus-jackpot--operator-head img {
    width: 60px;
    height: 60px;
    object-fit: contain;
}

.bonus-jackpot--operator-boxes {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bonus-jackpot--operator-box {
    width: 50%;
    max-width: 240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 10px;
}

.bonus-jackpot--operator-box:first-child {
    align-items: flex-end;
}

.bonus-jackpot--operator-text {
    font-size: 13px;
    color: #ffffff;
    font-family: "Red Hat Display", 'Nunito Sans';
    margin-bottom: 5px;
    font-weight: 500;
    padding: 0 3px;
}

.bonus-jackpot--operator-value {
    color: #fff;
    font-family: "Red Hat Display", 'Nunito Sans';
    font-size: 19px;
    font-weight: 1000;
    margin-bottom: 10px;
    background: #E91E63;
    padding: 10px 15px;
    border-radius: 10px;
}

.bonus-jackpot--operator-value.score {
    background: #3f51b5;
}

.bonus-jackpot--operator-more {
    position: absolute;
    right: 20px;
    top: 15px;
}

.bonus-jackpot--operator-more img {
    width: 22px;
    height: 22px;
    object-fit: contain;
    opacity: 0.7;
}

.bonus-jackpot--operator-status {
    position: absolute;
    z-index: 1;
    left: 20px;
    top: 16px;
    font-size: 12px;
    background: #8bc34a;
    padding: 2px 6px;
    border-radius: 5px;
    color: white;
    font-weight: 1000;
}

.bonus-jackpot--operator-status.red {
    background: #f44336;
}


.bonus-jackpot--operator-create {
    width: calc(50% - 15px);
    margin-right: 10px;
    padding: 20px 5px;
    border-radius: 10px;
    transition: transform 0.3s;
    margin-bottom: 10px;
    border: 2px dashed #cacaca;
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
}

.bonus-jackpot--operator-create:hover {
    transform: scale(1.005);
}

.bonus-jackpot--operator-create img {
    width: 50px;
    height: 50px;
    opacity: 0.5;
    object-fit: contain;
}

.bonus-jackpot--operator-create p {
    margin-top: 10px;
    font-size: 13px;
    color: #666;
}

.bonus-jackpot--modal-update {
    padding: 20px;
}

.bonus-jackpot--modal-switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
    padding: 15px 5px;
    border-top: 1px solid #cfcfcf;
}

.bonus-jackpot--modal-switch p {
    font-size: 14px;
    font-weight: bold;
    color: #333;
}

.bonus-jackpot--modal-button {
    background: #03a9f4;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    border-radius: 25px;
    color: white;
    font-weight: 800;
    cursor: pointer;
    transition: box-shadow 0.3s;
}

.bonus-jackpot--modal-button:hover {
    box-shadow: 0 0 10px #03a9f4;
}

@media screen and (max-width: 768px) {

    .ReactModal__Overlay.bonus-jackpot--overlay {
        padding: 20px;
    }

    .bonus-jackpot--container {
        padding: 20px;
        display: flex;
        flex-wrap: wrap;
    }

    .bonus-jackpot--operator,
    .bonus-jackpot--operator-create {
        width: 100%;
        margin-right: 0;
    }


    .bonus-jackpot--operator-boxes {
        flex-direction: column;
    }

    .bonus-jackpot--operator-box,
    .bonus-jackpot--operator-box:first-child {
        width: 100%;
        max-width: 100%;
        align-items: center;
    }

}





.bonus-jackpot--connect-container {
    padding: 20px;
}

.bonus-jackpot--connect-operator-label {
    font-size: 14px;
    font-weight: bold;
    padding-left: 3px;
    padding-bottom: 7px;
}

.bonus-jackpot--connect-operators {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 30px;
}

.bonus-jackpot--connect-operator {
    border: 1px solid #999;
    width: 100px;
    text-align: center;
    padding: 5px;
    margin-right: 10px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: normal;
    cursor: pointer;
    transition: background-color 0.3s, border 0.3s, color 0.3s;
}

.bonus-jackpot--connect-operator:hover {
    background: #8bc34a;
    border: 1px solid #8bc34a;
    color: #fff
}

.bonus-jackpot--connect-operator-selected,
.bonus-jackpot--connect-operator-selected:hover {
    background: #333;
    border: 1px solid #333;
    color: #fff;
    font-weight: bold;
}

.bonus-jackpot--connect-operator-disabled {
    background: #dadada;
    color: #999;
    border: 1px solid #ccc;
    cursor: default;
}

.bonus-jackpot--connect-operator-disabled:hover {
    background: #dadada;
    color: #999;
    border: 1px solid #ccc;
}




.bonus--modal-filter-container {
    padding: 20px;
}

.bonus--modal-filter-label {
    font-size: 14px;
    font-weight: bold;
    padding-left: 3px;
    padding-bottom: 7px;
}

.bonus-jackpot--filter-boxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 20px;
}

.bonus-jackpot--filter-box {
    border: 1px solid #ccc;
    color: #333;
    text-align: center;
    padding: 5px 12px;
    margin-right: 10px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: normal;
    cursor: pointer;
    transition: background-color 0.3s, border 0.3s, color 0.3s;
    margin-bottom: 10px;
}

.bonus-jackpot--filter-box:hover {
    background: #8bc34a;
    border: 1px solid #8bc34a;
    color: #fff
}

.bonus-jackpot--filter-box-selected,
.bonus-jackpot--filter-box-selected:hover {
    background: #333;
    border: 1px solid #333;
    color: #fff;
    font-weight: bold;
}


.bonus-jackpot--filter-dates {
    display: flex;
    flex-wrap: wrap;
}

.bonus-jackpot--filter-dates input {
    height: 40px;
    padding-left: 15px;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-right: 5px;
    margin-bottom: 10px;
}

.react-datepicker__day--in-range {
    background: #03a9f4 !important;
}

.bonus-jackpot--filter-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 20px;
}

.bonus-jackpot--filter-apply {
    width: calc(50% - 5px);
    background: #03a9f4;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
}

.bonus-jackpot--filter-clear {
    width: calc(50% - 5px);
    border: 1px solid #ccc;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
}