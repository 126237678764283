.menu {
    width: 300px;
    min-height: 100%;
    padding: 20px;
    position: relative;
    transition: width 0.3s;
    overflow: hidden;
}

.menu.minimized {
    width: 100px;
}

.menu-inner {
    min-height: 100%;
    width: 100%;
    background: rgba(46, 46, 72, 0.4);
    box-shadow: 0 0 2px rgb(46, 46, 72);
    border-radius: 25px;
    padding-bottom: 10px;
    backdrop-filter: blur(10px);
}


/* Menu Logo */
.menu-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 15px 20px 0 20px;
    text-decoration: none;
    height: 65px;
    width: 260px;
}

.menu-logo span {
    background: #03a9f4;
    border-radius: 12px;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-right: 10px;
}

.menu-logo span img {
    width: 18px;
    height: 18px;
    object-fit: contain;
}

.menu-logo h1 {
    font-weight: 1000;
    font-size: 20px;
    color: white;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.menu.minimized .menu-logo {
    width: 60px;
    height: 65px;
    padding: 15px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu.minimized .menu-logo span {
    margin-right: 0;
}

.menu.minimized .menu-logo h1 {
    display: none;
}




/* MENU BOX STYLES */
.menu-box {
    margin-top: 10px;
}

.menu-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 50px;
    padding: 0 20px;
    text-decoration: none;
    margin-bottom: 5px;
    width: 260px;
}

.menu.minimized .menu-item {
    width: 60px;
}

.menu-item span {
    color: white;
    margin-left: 10px;
    font-size: 14px;
    transition: font-weight 0.3s;
    font-weight: 200;
}

.menu.minimized .menu-item span {
    display: none;
}

.menu-item.active span {
    font-weight: 800;
}

.menu-image {
    width: 20px;
    height: 32px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.menu-item .menu-image::after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;
    background: #03a9f4;
    width: 100%;
    height: 3px;
    border-radius: 3px;
    transform: translateX(-20px);
    transition: transform 0.3s;
}

.menu-item.active .menu-image::after {
    transform: translateX(0);
}


.menu-item img {
    width: 18px;
    height: 18px;
    object-fit: contain;
}

.menu-space {
    height: 20px;
}



/* MENU ARROW STYLES */
.menu-arrow {
    position: absolute;
    right: 11px;
    top: calc(50vh - 25px);
    background: #19192f;
    width: 18px;
    height: 50px;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s;
}

.menu-arrow:hover {
    background: #0d0d1f;
}

.menu-arrow img {
    width: 12px;
    height: 12px;
    object-fit: contain;
    transition: transform 0.4s;
}

.menu-arrow.active img {
    transform: rotate(180deg);
}





@media screen and (max-width: 900px) {
    .menu {
        display: none;
    }
}





/* MOBILE MENU STYLES */
.mobile-menu-container {
    display: none;
    overflow: hidden;
}

.mobile-menu {
    position: fixed;
    bottom: 10px;
    left: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2e2e48;
    padding: 10px 0;
    border-radius: 30px;
    box-shadow: 0 0 3px #20203d;
    overflow: hidden;
    z-index: 5;
}

.mobile-menu-item {
    width: 20%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.mobile-menu-image {
    width: 30px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.mobile-menu-item .mobile-menu-image::after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;
    background: #03a9f4;
    width: 100%;
    height: 3px;
    border-radius: 3px;
    transform: translateX(-30px);
    transition: transform 0.3s;
}

.mobile-menu-item.active .mobile-menu-image::after {
    transform: translateX(0);
}

.mobile-menu-image img {
    width: 22px;
    height: 22px;
    object-fit: contain;
}


/* MOBILE MENU BAR STYLES */
.mobile-menu-bar {
    position: fixed;
    z-index: 5;
    right: 10px;
    width: 280px;
    bottom: 80px;
    background: white;
    border-radius: 25px;
    box-shadow: 0 0 3px #20203d;
    padding: 15px;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s linear 0.2s, opacity 0.2s linear;
}

.mobile-menu-bar.active {
    transition: visibility 0s, opacity 0.2s linear;
    visibility: visible;
    opacity: 1;
}

.mobile-menu-bar-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 50px;
    text-decoration: none;
}

.mobile-menu-bar-item span {
    padding-left: 6px;
    color: #141414;
    font-size: 14px;
    font-weight: 300;
    transition: font-weight 0.4s;
}

.mobile-menu-bar-item.active span {
    font-weight: 800;
}

.mobile-menu-bar-image {
    width: 26px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.mobile-menu-bar-item .mobile-menu-bar-image::after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;
    background: #03a9f4;
    width: 100%;
    height: 3px;
    border-radius: 3px;
    transform: translateX(-30px);
    transition: transform 0.3s;
}

.mobile-menu-bar-item.active .mobile-menu-bar-image::after {
    transform: translateX(0);
}

.mobile-menu-bar-image img {
    width: 22px;
    height: 22px;
    object-fit: contain;
    filter: invert(0.8);
}

@media screen and (max-width: 900px) {
    .menu {
        display: none;
    }

    .mobile-menu-container {
        display: block;
    }
}